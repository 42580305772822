import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { GlobalState } from '../../../context/GlobalContext'

const ListLembaga = ({ item, getDataLembaga }) => {

    const { baseUrl, setDataLembaga } = useContext(GlobalState)

    const deleteLembaga = () => {

        var config = {
            method: 'delete',
            url: `${baseUrl}/api/lembaga/hapusLembaga.php?id=${item.id}`,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        axios(config)
            .then(response => {
                console.log(response.data.msg);
                getDataLembaga()
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="list-berita-container">
            <div className="list-title">{item.title}</div>
            {/* <div className="list-desc"><span className="author-list">{item.author} | </span><span className="date-list">{item.date}</span></div> */}
            <div className="image-list"><img src={`${item.imgUrl}`} alt={item.title} /></div>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}>
                <div className="hapus-berita" onClick={deleteLembaga}>
                    <i id="remove-list-berita" className="fa fa-trash" aria-hidden="true" title="Hapus Data" ></i> Hapus
                </div>
                <Link to='/ubah-lembaga'>
                    <div className="ubah-berita" onClick={()=>setDataLembaga(item)}>
                        <i id="remove-list-berita" className="fa fa-edit" aria-hidden="true" title="Ubah Data" ></i> Ubah
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default ListLembaga
