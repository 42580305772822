import React, { useEffect, useState, useContext } from 'react'
import { GlobalState } from '../../../context/GlobalContext'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

const DataUbahAset = () => {

    const { baseUrl, dataAset } = useContext(GlobalState)

    const [data, setData] = useState({
        id: "",
        title: "",
        file: null,
        desc: ""
    })

    const { id, title, file, desc } = data

    var history = useHistory()

    useEffect(() => {
        setData({
            ...data,
            id: dataAset.id,
            title: dataAset.title,
            file: dataAset.imgUrl,
            desc: dataAset.desc
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const uploadImageAset = () => {
        const FormData = require('form-data');
        const dataBaru = new FormData();
        dataBaru.append('anyfile', file);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/upload/uploadImageAset.php`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: dataBaru
        };

        axios(config)
            .then(function (response) {
                console.log(response.data)
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const ubahAset = () => {

        uploadImageAset()

        const FormData = require('form-data');
        const data = new FormData();
        data.append('title', title);
        data.append('imgUrl', `${baseUrl}/images/aset/${file !== "" ? file.name : file}`);
        data.append('desc', desc);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/aset/ubahAset.php?id=${id}`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        axios(config)
            .then(response => {
                console.log(response.data);
                history.push('/admin-aset')
                window.scrollTo(0, 0)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="tambah-warga-container">
            <h3>Halaman Ubah Data Aset</h3>
            <div className="deskripsi-tambah-warga">
                <span>Silahkan Ubah form berikut untuk mengubah aset :</span>
            </div>
            <div className="data-warga">
                <form>
                    <div className="item-data">
                        <span>Judul :</span>
                        <input
                            type="text"
                            name="title"
                            onChange={(e) => setData({ ...data, title: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Upload Gambar :</span>
                        <input
                            type="file"
                            onChange={(e) => setData({ ...data, file: e.target.files[0] })} />
                        <span style={{ fontSize: 9, fontWeight: "bold", paddingLeft: 8 }}>format data (.jpg/.jpeg/.png)</span>
                    </div>
                    <div className="item-data">
                        <span>Deskripsi :</span>
                        <textarea
                            name="desc"
                            onChange={(e) => setData({ ...data, desc: e.target.value })} />
                    </div>
                    <div className="update-data-warga-btn" onClick={ubahAset}>
                        <span>Ubah</span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DataUbahAset