import React from 'react'
import './DataBerita.css'
import DetailBeritaLain from './DetailBeritaLain'

const DataBerita = ({ detailBerita, berita, detailItemBerita }) => {

    const nBerita = berita.length
    const randomId1 = Math.floor(Math.random() * nBerita)
    const randomId2 = Math.floor(Math.random() * nBerita)
    const bacaJuga1 = berita[randomId1]
    const bacaJuga2 = berita[randomId2]

    const bacaBerita1 = () => {
        detailItemBerita(bacaJuga1)
        window.scrollTo(0,0)
    }

    const bacaBerita2 = () => {
        detailItemBerita(bacaJuga2)
        window.scrollTo(0,0)
    }

    return (
        <>
            {
                (detailBerita !== null) ? (
                    <div className="data-berita-container">
                        <div className="title-berita">
                            <span className="title">{detailBerita.title}</span>
                            <span className="title-desc">Penulis : {detailBerita.author} | {detailBerita.date}</span>
                        </div>
                        <div className="image-berita">
                            <img src={detailBerita.imgUrl} alt="detail berita" />
                            <span>{detailBerita.desc}</span>
                        </div>
                        <div className="body-berita">
                            <span className="paragraf">{detailBerita.text1}</span>
                            <div className="baca-juga">
                                <span>Baca Juga :</span>
                                <span onClick={bacaBerita1}>{bacaJuga1.title}</span>
                            </div>
                            <span className="paragraf">{detailBerita.text2}</span>
                            <div className="baca-juga">
                                <span>Baca Juga :</span>
                                <span onClick={bacaBerita2}>{bacaJuga2.title}</span>
                            </div>
                            <span className="paragraf">{detailBerita.text3}</span>
                        </div>
                        <div className="berita-lain">
                            <span className="title-berita-lain">Berita Lainnya :</span>
                            {
                                berita.reverse().map(item => {
                                    return (
                                        <DetailBeritaLain item={item} detailItemBerita={detailItemBerita} key={item.id} />
                                    )
                                })
                            }
                        </div>
                    </div>
                ) : (null)
            }
        </>
    )
}

export default DataBerita
