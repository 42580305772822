import React from 'react'

const DetailBeritaLain = ({item, detailItemBerita}) => {

    const pilihBerita = () => {
        detailItemBerita(item)
        window.scrollTo(0,0)
    }

    return (
        <div className="detail-berita-lain-container" onClick={pilihBerita}>
            <div className="deskripsi-judul">
                <span>{item.title}</span>
                <span>{item.author}</span>
                <span>{item.date}</span>
            </div>
            <div className="gambar-berita">
                <img src={item.imgUrl} alt="gambar berita"/>
            </div>
        </div>
    )
}

export default DetailBeritaLain
