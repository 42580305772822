import React, { useContext } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import closeIcon from '../../assets/icon/close-icon.png'
import LogoutIcon from '../../assets/icon/logout-icon.png'
import { GlobalState } from '../../context/GlobalContext'
import { sidebarData } from './sidebarData'
import './Sidebar.css'

const Sidebar = () => {

    const { setAuthenticated, setSidebar } = useContext(GlobalState)
    
    let history = useHistory()

    const handleLogout = () => {
        setAuthenticated(false)
        window.location.reload()
        history.push('/')
    }

    return (
        <div className="sidebar-container">
            <div className="menu-title">MENU ADMIN</div>
            <div className="menu-group">
                {
                    sidebarData.map(item => {
                        return (
                            <NavLink key={item.id} activeClassName="active" to={item.path} onClick={() => setSidebar(false)}>
                                <img src={item.icon} alt={item.name} />
                                <div className="menu-admin">
                                    {item.name}
                                </div>
                            </NavLink>
                        )
                    })
                }
            </div>
            <div className="menu-logout" onClick={handleLogout}>
                <img src={LogoutIcon} alt="logout" />
                <div className="menu-admin">Keluar</div>
            </div>
            <div className="close-menu" onClick={() => setSidebar(false)}>
                <img src={closeIcon} alt="close-menu" />
            </div>
        </div>
    )
}

export default Sidebar
