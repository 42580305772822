import React, { useState, useContext, useEffect } from 'react'
// import PasarDesaku from './PasarDesaku'
import { GlobalState } from '../../context/GlobalContext'
import axios from 'axios'
import './DataPasar.css'
import CartItem from './CartItem'

const DataPasar = () => {

    const { baseUrl } = useContext(GlobalState)

    const [produk, setProduk] = useState([])

    const produkUrl = `${baseUrl}/api/produk/produk.php`

    const getDataProduk = () => {
        axios.get(produkUrl)
            .then(result => {
                setProduk(result.data)
            })
    }

    const [itemFiltered, setItemFiltered] = useState('')
    const [newItem, setNewItem] = useState('')

    const handleCari = () => {
        setNewItem(itemFiltered)
    }

    useEffect(() => {
        getDataProduk()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="pembayaran-container">
            <h3>Galeri Produk Desaku</h3>
            <div className="pasar-desaku-container">
                <div className="filter-produk">
                    <span>Produk yang terdaftar di Galeri Produk UMKM Desaku</span>
                    <div className="input-filter">
                        <input type="text" placeholder="Cari Produk" onChange={(e) => setItemFiltered(e.target.value)} />
                        <div className="btn-small" onClick={handleCari} >Cari</div>
                    </div>
                </div>
            </div>
            <div className="display-produk">
                <div className="produk-container">
                    <div className="produk-card-container">
                        {
                            produk.length > 0 ?
                            (produk.filter(item => {
                                if (item.name.toLowerCase().includes(newItem.toLowerCase())) {
                                    return item
                                } else if (newItem === '') {
                                    return item
                                } else {
                                    return null
                                }
                            }).map(item => {
                                return (
                                    <CartItem key={item.id} item={item} />
                                )
                            })):(
                                <div style={{fontSize:12, color:"gray", textAlign:"center", width:"100%"}}>Belum Ada Produk Ditambahkan</div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DataPasar
