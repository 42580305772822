import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { GlobalState } from '../../../context/GlobalContext'

const ListProduk = ({ item, getDataProduk }) => {

    const { setProdukUmkm, baseUrl } = useContext(GlobalState)

    const deleteProduk = () => {

        var config = {
            method: 'delete',
            url: `${baseUrl}/api/produk/hapusProduk.php?id=${item.id}`,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        axios(config)
            .then(response => {
                console.log(response.data.msg);
                getDataProduk()
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const ubahProduk = () => {
        setProdukUmkm(item)
    }

    return (
        <div className="list-produk-container">
            <div className="detail-produk-container">
                <div className="image-produk"><img src={`${item.imgUrl}`} alt={item.title} /></div>
                <div className="deskripsi-produk">
                    <div className="nama-produk">{item.name}</div>
                    <div className="harga-produk">Harga : Rp. {item.price} s/d Rp. {item.price2}</div>
                    <div className="stok-produk">Stok : {item.stok}</div>
                    <div className="phone-produk">Phone : {item.phone}</div>
                    <div className="action-product">
                        <Link to='/ubah-produk'>
                            <div className="edit-produk" onClick={ubahProduk}>
                                <i className="fa fa-edit" aria-hidden="true" title="Ubah Data"></i> Ubah
                            </div>
                        </Link>
                        <div className="hapus-produk" onClick={deleteProduk} >
                            <i className="fa fa-trash" aria-hidden="true" title="Hapus Data" ></i> Hapus
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListProduk
