import React from 'react'
import './KantorDesa.css'

const KantorDesa = () => {
    return (
        <div className="peta">
            <iframe title="Peta Desa Mekarjaya" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2834.0728945016835!2d106.83330557567147!3d-7.309293880371893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e680dea76000a81%3A0x27e9461d871283c0!2sVillage%20Office%20Mekarjaya!5e1!3m2!1sen!2sid!4v1649406474243!5m2!1sen!2sid" width="100%" height="250" style={{ border: 0 }} loading="lazy"></iframe>
        </div>
    )
}

export default KantorDesa
