import React, { useState, useEffect, useContext } from 'react'
import { GlobalState } from '../../context/GlobalContext'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import axios from 'axios'
import './Slider.css'

const Slider = () => {

    const { baseUrl } = useContext(GlobalState)

    const [carousel, setCarousel] = useState([])

    const apiCarousel = `${baseUrl}/api/carousel/carousel.php`

    // get carousel data
    const getCarouselData = async () => {
        await axios.get(apiCarousel)
            .then(res => setCarousel(res.data))
    }

    useEffect(() => {
        getCarouselData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Carousel showThumbs={false} interval={6000} autoPlay={true} infiniteLoop={true} showArrows={false} showStatus={false} showIndicators={false} >
            {
                carousel.length > 0 && (
                    carousel.map((slide, index) => {
                        return (
                            <div className="slider-container" key={index}>
                                <div className="slide">
                                    <img src={slide.imgUrl} alt="slider" />
                                    <span className="legend">{slide.caption}</span>
                                </div>
                            </div>
                        )
                    })
                )
            }
        </Carousel>
    )
}

export default Slider

// export class Slider extends Component {
//     render() {
//         return (
//             <Carousel showThumbs={false} interval={6000} autoPlay={true} infiniteLoop={true} showArrows={false} showStatus={false} showIndicators={false} >
//                 {
//                     this.props.slides.length > 0 && (
//                         this.props.slides.map((slide, index) => {
//                             return (
//                                 <div className="slider-container" key={index}>
//                                     <div className="slide">
//                                         <img src={slide.img_url} alt="slider" />
//                                         <span className="legend">{slide.caption}</span>
//                                     </div>
//                                 </div>
//                             )
//                         })
//                     )
//                 }
//             </Carousel>
//         )
//     }
// }

// export default Slider
