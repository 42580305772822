import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './DataSurat.css'

const DataSurat = () => {

    const [surat] = useState([
        { jenis: "Surat Keterangan Domisili", link: "/surat/keterangan-domisili" },
        { jenis: "Surat Pengantar SKCK", link: "/surat/pengantar-skck" },
        { jenis: "Surat Keterangan Tidak Mampu", link: "/surat/keterangan-tidak-mampu" },
        { jenis: "Surat Keterangan Penghasilan Orang Tua", link: "/surat/keterangan-penghasilan" },
        { jenis: "Surat Keterangan Kehilangan", link: "/surat/keterangan-kehilangan" },
        { jenis: "Surat Keterangan Usaha", link: "/surat/keterangan-usaha" },
        { jenis: "Surat Keterangan Domisili Usaha", link: "/surat/keterangan-domisili-usaha" },
        { jenis: "Surat Keterangan Penduduk", link: "/surat/keterangan-penduduk" },
        { jenis: "Surat Keterangan Umum", link: "/surat/keterangan-umum" },
        { jenis: "Surat Pengantar Umum", link: "/surat/pengantar-umum" }
    ])

    return (
        <div className="data-surat-container">
            <Link to='/'>
                <div className="to-dashboard"><i className="fa fa-home" aria-hidden="true"></i> kembali ke dashboard</div>
            </Link>
            <h3>Layanan Surat Elektronik</h3>
            <span>Daftar surat elektronik yang tersedia antara lain:</span>
            <div className="jenis-surat">
                {
                    surat.map((item, index) => {
                        return (
                            <div className="item-surat" key={index}>
                                <span>{index + 1}). {item.jenis}</span>
                                <Link to={item.link}>
                                    <div className="create">
                                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                        <span>Buat Surat</span>
                                    </div>
                                </Link>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default DataSurat
