import React, { useEffect, useState, useContext } from 'react'
import { GlobalState } from '../../../context/GlobalContext'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

const DataUbahLembaga = () => {

    const { baseUrl, dataLembaga } = useContext(GlobalState)

    const [data, setData] = useState({
        id: "",
        title: "",
        file: null,
        text1: "",
        text2: "",
        text3: ""
    })

    const { id, title, file, text1, text2, text3 } = data

    var history = useHistory()

    useEffect(() => {
        setData({
            ...data,
            id: dataLembaga.id,
            title: dataLembaga.title,
            file: dataLembaga.imgUrl,
            text1: dataLembaga.text1,
            text2: dataLembaga.text2,
            text3: dataLembaga.text3,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const uploadImageLembaga = () => {
        const FormData = require('form-data');
        const dataBaru = new FormData();
        dataBaru.append('anyfile', file);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/upload/uploadImageLembaga.php`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: dataBaru
        };

        axios(config)
            .then(function (response) {
                console.log(response.data)
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const ubahLembaga = () => {

        uploadImageLembaga()

        const FormData = require('form-data');
        const data = new FormData();
        data.append('title', title);
        data.append('imgUrl', `${baseUrl}/images/lembaga/${file !== "" ? file.name : file}`);
        data.append('text1', text1);
        data.append('text2', text2);
        data.append('text3', text3);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/lembaga/ubahLembaga.php?id=${id}`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        axios(config)
            .then(response => {
                console.log(response.data);
                history.push('/admin-lembaga')
                window.scrollTo(0, 0)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="tambah-warga-container">
            <h3>Halaman Ubah Data Lembaga</h3>
            <div className="deskripsi-tambah-warga">
                <span>Silahkan Ubah form berikut untuk mengubah lembaga :</span>
            </div>
            <div className="data-warga">
                <form>
                    <div className="item-data">
                        <span>Judul :</span>
                        <input
                            type="text"
                            name="title"
                            onChange={(e) => setData({ ...data, title: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Upload Gambar :</span>
                        <input
                            type="file"
                            name="imgUrl"
                            onChange={(e) => setData({ ...data, file: e.target.files[0] })} />
                        <span style={{ fontSize: 9, fontWeight: "bold", paddingLeft: 8 }}>format data (.jpg/.jpeg/.png)</span>
                    </div>
                    <div className="item-data">
                        <span>Paragraf Pertama :</span>
                        <textarea
                            name="text1"
                            onChange={(e) => setData({ ...data, text1: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Paragraf Kedua :</span>
                        <textarea
                            name="text2"
                            onChange={(e) => setData({ ...data, text2: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Paragraf Ketiga :</span>
                        <textarea
                            name="text3"
                            onChange={(e) => setData({ ...data, text3: e.target.value })} />
                    </div>
                    <div className="update-data-warga-btn" onClick={ubahLembaga}>
                        <span>Ubah</span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DataUbahLembaga