import React from 'react'
import BannerImage from '../../assets/picture/afiliasi/banner.jpg'
import './BannerTop.css'

const BannerTop = () => {
    return (
        <div className="banner">
            <img src={BannerImage} alt="desaku.app" />
        </div>
    )
}

export default BannerTop
