import React from 'react'
import BottomImage from '../../assets/picture/afiliasi/griya-firdaus-1.jpg'
import './BannerTop.css'

const BannerBottom = () => {
    return (
        <div className="banner-bottom">
            <img src={BottomImage} alt="tanah-kavling-griya-firdaus-1" />
        </div>
    )
}

export default BannerBottom
