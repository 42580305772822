import React, { useState, useEffect, useContext } from 'react'
import { GlobalState } from '../../../context/GlobalContext'
import axios from 'axios'

const DataUbahProfil = () => {

    const { baseUrl } = useContext(GlobalState)

    const [data, setData] = useState({
        sejarah: "",
        sosial: "",
        ekonomi: "",
        utara: "",
        selatan: "",
        timur: "",
        barat: "",
        namaKades: "",
        tempatLahir: "",
        tanggalLahir: "",
        tanggalPengangkatan: "",
        alamat: "",
        rt: "",
        rw: "",
        desa: "",
        kecamatan: "",
        kabupaten: "",
        senin: "",
        selasa: "",
        rabu: "",
        kamis: "",
        jumat: "",
        sabtu: "",
        minggu: ""
    })

    const {
        sejarah,
        sosial,
        ekonomi,
        utara,
        selatan,
        timur,
        barat,
        namaKades,
        tempatLahir,
        tanggalLahir,
        tanggalPengangkatan,
        alamat,
        rt,
        rw,
        desa,
        kecamatan,
        kabupaten,
        senin,
        selasa,
        rabu,
        kamis,
        jumat,
        sabtu,
        minggu
    } = data

    const apiProfil = `${baseUrl}/api/profil/profil.php`

    // get latest profile
    const getLatestProfile = async () => {
        await axios.get(apiProfil)
            .then(res => {
                setData({
                    ...data,
                    sejarah: res.data[0]?.sejarah,
                    sosial: res.data[0]?.sosial,
                    ekonomi: res.data[0]?.ekonomi,
                    utara: res.data[0]?.utara,
                    selatan: res.data[0]?.selatan,
                    timur: res.data[0]?.timur,
                    barat: res.data[0]?.barat,
                    namaKades: res.data[0]?.namaKades,
                    tempatLahir: res.data[0]?.tempatLahir,
                    tanggalLahir: res.data[0]?.tanggalLahir,
                    tanggalPengangkatan: res.data[0]?.tanggalPengangkatan,
                    alamat: res.data[0]?.alamat,
                    rt: res.data[0]?.rt,
                    rw: res.data[0]?.rw,
                    desa: res.data[0]?.desa,
                    kecamatan: res.data[0]?.kecamatan,
                    senin: res.data[0]?.senin,
                    selasa: res.data[0]?.selasa,
                    rabu: res.data[0]?.rabu,
                    kamis: res.data[0]?.kamis,
                    jumat: res.data[0]?.jumat,
                    sabtu: res.data[0]?.sabtu,
                    minggu: res.data[0]?.minggu
                })
            })
    }

    useEffect(() => {
        getLatestProfile()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [file, setFile] = useState(null)

    const uploadImageKades = () => {
        
        const FormData = require('form-data');
        const dataBaru = new FormData();
        dataBaru.append('anyfile', file);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/upload/uploadImageKades.php`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: dataBaru
        };

        axios(config)
            .then(function (response) {
                console.log(response.data)
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const ubahProfil = () => {

        uploadImageKades()

        const FormData = require('form-data');
        const data = new FormData();
        data.append('sejarah', sejarah);
        data.append('sosial', sosial);
        data.append('ekonomi', ekonomi);
        data.append('utara', utara);
        data.append('selatan', selatan);
        data.append('timur', timur);
        data.append('barat', barat);
        data.append('namaKades', namaKades);
        data.append('imgKades', `${baseUrl}/images/profil/` + file?.name);
        data.append('tempatLahir', tempatLahir);
        data.append('tanggalLahir', tanggalLahir);
        data.append('tanggalPengangkatan', tanggalPengangkatan);
        data.append('alamat', alamat);
        data.append('rt', rt);
        data.append('rw', rw);
        data.append('desa', desa);
        data.append('kecamatan', kecamatan);
        data.append('kabupaten', kabupaten);
        data.append('senin', senin);
        data.append('selasa', selasa);
        data.append('rabu', rabu);
        data.append('kamis', kamis);
        data.append('jumat', jumat);
        data.append('sabtu', sabtu);
        data.append('minggu', minggu);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/profil/ubahProfil.php?id=1`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        axios(config)
            .then(response => {
                console.log(response.data);
                window.scrollTo(0, 0)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="tambah-warga-container">
            <h3>Halaman Ubah Data Profil</h3>
            <div className="deskripsi-tambah-warga">
                <span>Silahkan Isi form berikut untuk mengubah profil :</span>
            </div>
            <div className="data-warga">
                <form>
                    <div className="item-data">
                        <span>Sejarah :</span>
                        <textarea type="textarea" name="sejarah" value={sejarah} onChange={(e) => setData({ ...data, sejarah: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Sosial :</span>
                        <textarea type="textarea" name="sosial" value={sosial} onChange={(e) => setData({ ...data, sosial: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Ekonomi :</span>
                        <textarea type="textarea" name="ekonomi" value={ekonomi} onChange={(e) => setData({ ...data, ekonomi: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Batas Utara :</span>
                        <input type="text" name="utara" value={utara} onChange={(e) => setData({ ...data, utara: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Batas Selatan :</span>
                        <input type="text" name="selatan" value={selatan} onChange={(e) => setData({ ...data, selatan: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Batas Timur :</span>
                        <input type="text" name="timur" value={timur} onChange={(e) => setData({ ...data, timur: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Batas Barat :</span>
                        <input type="text" name="barat" value={barat} onChange={(e) => setData({ ...data, barat: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Nama Kades :</span>
                        <input type="text" name="namaKades" value={namaKades} onChange={(e) => setData({ ...data, namaKades: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Gambar Kades:</span>
                        <input type="file" name="imgKades" onChange={(e) => setFile(e.target.files[0])} />
                        <span style={{ fontSize: 9, fontWeight: "bold", paddingLeft: 8 }}>format data (.jpg/.jpeg/.png)</span>
                    </div>
                    <div className="item-data">
                        <span>Tempat Lahir :</span>
                        <input type="text" name="tempatLahir" value={tempatLahir} onChange={(e) => setData({ ...data, tempatLahir: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Tanggal Lahir:</span>
                        <input type="date" name="tanggalLahir" value={tanggalLahir} onChange={(e) => setData({ ...data, tanggalLahir: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Tanggal Pengangkatan:</span>
                        <input type="date" name="tanggalPengangkatan" value={tanggalPengangkatan} onChange={(e) => setData({ ...data, tanggalPengangkatan: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Alamat :</span>
                        <input type="text" name="alamat" value={alamat} onChange={(e) => setData({ ...data, alamat: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Rt :</span>
                        <input type="text" name="rt" value={rt} onChange={(e) => setData({ ...data, rt: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Rw :</span>
                        <input type="text" name="rw" value={rw} onChange={(e) => setData({ ...data, rw: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Desa :</span>
                        <input type="text" name="desa" value={desa} onChange={(e) => setData({ ...data, desa: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Kecamatan :</span>
                        <input type="text" name="kecamatan" value={kecamatan} onChange={(e) => setData({ ...data, kecamatan: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Kabupaten :</span>
                        <input type="text" name="kabupaten" value={kabupaten} onChange={(e) => setData({ ...data, kabupaten: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Jam Kerja Senin :</span>
                        <input type="text" name="senin" value={senin} onChange={(e) => setData({ ...data, senin: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Jam Kerja Selasa :</span>
                        <input type="text" name="selasa" value={selasa} onChange={(e) => setData({ ...data, selasa: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Jam Kerja Rabu :</span>
                        <input type="text" name="rabu" value={rabu} onChange={(e) => setData({ ...data, rabu: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Jam Kerja Kamis :</span>
                        <input type="text" name="kamis" value={kamis} onChange={(e) => setData({ ...data, kamis: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Jam Kerja Jum'at :</span>
                        <input type="text" name="jumat" value={jumat} onChange={(e) => setData({ ...data, jumat: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Jam Kerja Sabtu :</span>
                        <input type="text" name="sabtu" value={sabtu} onChange={(e) => setData({ ...data, sabtu: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Jam Kerja Minggu :</span>
                        <input type="text" name="minggu" value={minggu} onChange={(e) => setData({ ...data, minggu: e.target.value })} />
                    </div>
                    <div className="update-data-warga-btn" onClick={ubahProfil}>
                        <span>Ubah</span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DataUbahProfil