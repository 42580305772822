import React, { useState, useContext } from 'react'
import { GlobalState } from '../../../context/GlobalContext'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

const DataTambahCarousel = () => {

    const { baseUrl } = useContext(GlobalState)

    const [data, setData] = useState({
        caption: ""
    })

    const { caption } = data

    const [file, setFile] = useState(null)

    var history = useHistory()

    const uploadImageCarousel = () => {
        const FormData = require('form-data');
        const dataBaru = new FormData();
        dataBaru.append('anyfile', file);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/upload/uploadImageCarousel.php`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: dataBaru
        };

        axios(config)
            .then(function (response) {
                console.log(response.data)
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const TambahCarousel = () => {

        uploadImageCarousel()

        const FormData = require('form-data');
        const data = new FormData();
        data.append('caption', caption);
        data.append('imgUrl', `${baseUrl}/images/carousel/` + file.name);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/carousel/tambahCarousel.php`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        axios(config)
            .then(response => {
                console.log(response.data);
                history.push('/admin-carousel')
                window.scrollTo(0, 0)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="tambah-warga-container">
            <h3>Halaman Tambah Data Carousel</h3>
            <div className="deskripsi-tambah-warga">
                <span>Silahkan Isi form berikut untuk menambahkan carousel :</span>
            </div>
            <div className="data-warga">
                <form>
                    <div className="item-data">
                        <span>Upload Gambar :</span>
                        <input type="file" name="imgUrl" onChange={(e) => setFile(e.target.files[0])} />
                        <span style={{ fontSize: 9, fontWeight: "bold", paddingLeft: 8 }}>format data (.jpg/.jpeg/.png)</span>
                    </div>
                    <div className="item-data">
                        <span>Caption :</span>
                        <textarea type="textarea" name="caption" onChange={(e) => setData({ ...data, caption: e.target.value })} />
                    </div>
                    <div className="update-data-warga-btn" onClick={TambahCarousel}>
                        <span >Tambah</span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DataTambahCarousel