import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { GlobalState } from '../../../context/GlobalContext'

const DataAdminPerangkat = () => {

    const { baseUrl } = useContext(GlobalState)

    const [nama, setNama] = useState('')
    const [jabatan, setJabatan] = useState('')
    const [file, setFile] = useState('')
    const [info, setInfo] = useState(null)
    const [perangkat, setPerangkat] = useState([])
    const [formUbahPerangkat, setFormUbahPerangkat] = useState(false)
    const [edit, setEdit] = useState(null)
    const [idx, setIdx] = useState(null)

    const apiPerangkat = `${baseUrl}/api/perangkat/perangkat.php`

    const getApiPerangkat = async () => {

        await axios.get(apiPerangkat)
            .then(response => {
                setPerangkat(response.data)
            })
    }

    const uploadImagePerangkat = () => {
        const FormData = require('form-data');
        const dataBaru = new FormData();
        dataBaru.append('anyfile', file);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/upload/uploadImagePerangkat.php`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: dataBaru
        };

        axios(config)
            .then(function (response) {
                console.log(response.data)
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const tambahPerangkat = () => {

        uploadImagePerangkat()

        var FormData = require('form-data');
        var body = new FormData();
        body.append('jabatan', jabatan);
        body.append('nama', nama);
        body.append('imgUrl', `${baseUrl}/images/perangkat/${file!==''?file.name:file}`);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/perangkat/tambahPerangkat.php`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: body
        };

        if ((nama === "") && (jabatan === "")) {
            setInfo('Nama dan Jabatan Tidak Boleh Kosong!')
            getApiPerangkat()
        } else {
            axios(config)
                .then(function (response) {
                    console.log(response);
                    setInfo(null)
                    getApiPerangkat()
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    const hapusPerangkat = (id) => {
        var config = {
            method: 'delete',
            url: `${baseUrl}/api/perangkat/hapusPerangkat.php?id=${id}`,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        axios(config)
            .then(function (response) {
                console.log(response.data);
                getApiPerangkat()
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    const ubahPerangkat = (id) => {
        if ((jabatan !== '') && (nama !== '')) {

            uploadImagePerangkat()

            var FormData = require('form-data');
            var dataBaru = new FormData();
            dataBaru.append('jabatan', jabatan);
            dataBaru.append('nama', nama);
            dataBaru.append('imgUrl', `${baseUrl}/images/perangkat/${file!==""?file.name:file}`);

            var config = {
                method: 'post',
                url: `${baseUrl}/api/perangkat/ubahPerangkat.php?id=${id}`,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: dataBaru
            };

            axios(config)
                .then(function (response) {
                    console.log((response.data));
                    setFormUbahPerangkat(false)
                    getApiPerangkat()
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    const formUbahData = (id) => {
        setFormUbahPerangkat(true)
        setIdx(id)
        setEdit(true)
    }

    const handleUbah = () => {
        ubahPerangkat(idx)
        setFormUbahPerangkat(false)
        setEdit(false)
        getApiPerangkat()
    }

    const handleCancel = () => {
        setFormUbahPerangkat(false)
        setInfo(null)
        setEdit(false)
    }

    useEffect(() => {
        getApiPerangkat()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="deskripsi">
            <span className="deskripsi-subtitle">Data Perangkat Desa</span>
            <span>Tambah data perangkat :</span>
            <div className="data-content">
                <form>
                    <div className="item-data">
                        <span>Nama Perangkat :</span>
                        <input type="text" name="nama-perangkat" placeholder="Nama Perangkat" onChange={e => setNama(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>Jabatan :</span>
                        <input type="text" name="jabatan" placeholder="Jabatan" onChange={e => setJabatan(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>Upload Gambar :</span>
                        <input type="file" name="imgUrl" onChange={(e) => setFile(e.target.files[0])} />
                        <span style={{ fontSize: 9, fontWeight: "bold", paddingLeft: 8 }}>format data (.jpg/.jpeg/.png)</span>
                    </div>
                    {(nama === "" && jabatan === "") && <div className="alert-info">{info}</div>}
                    <div className="tambah-btn" onClick={tambahPerangkat}>
                        Tambah
                    </div>
                </form>
            </div>
            <div className="detail-content">
                <table className="styled-table">
                    <thead>
                        <tr>
                            <td>No</td>
                            <td>Nama</td>
                            <td>Jabatan</td>
                            <td>Aksi</td>
                        </tr>
                    </thead>
                    <tbody>
                        {perangkat.map((item, idx) => {
                            return (
                                <tr key={idx}>
                                    <td>{idx + 1}</td>
                                    <td>{item.nama}</td>
                                    <td>{item.jabatan}</td>
                                    <td>
                                        <div className="action">
                                            <i id="icon-edit" className="fa fa-pencil-square-o" aria-hidden="true" title="Edit Data" onClick={() => formUbahData(item.id)}></i>
                                            <i id="icon-remove" className="fa fa-trash" aria-hidden="true" title="Hapus Data" onClick={() => hapusPerangkat(item.id)}></i>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            {formUbahPerangkat ? (
                <div className="pop-up-ubah-data">
                    <div className="ubah-data-container">
                        <span className="ubah-data-subtitle">Ubah data perangkat</span>
                        {(edit && nama === "" && jabatan === "") && <div className="alert-info">{info}</div>}
                        <div className="pop-up-data-content">
                            <form>
                                <div className="item-data">
                                    <span>Nama Perangkat :</span>
                                    <input type="text" nama="nama-perangkat" onChange={e => setNama(e.target.value)} />
                                </div>
                                <div className="item-data">
                                    <span>Jabatan :</span>
                                    <input type="text" nama="jabatan" onChange={e => setJabatan(e.target.value)} />
                                </div>
                                <div className="item-data">
                                    <span>Upload Gambar :</span>
                                    <input type="file" name="imgUrl" onChange={(e) => setFile(e.target.files[0])} />
                                    <span style={{ fontSize: 9, fontWeight: "bold", paddingLeft: 8 }}>format data (.jpg/.jpeg/.png)</span>
                                </div>
                                {(nama === "" && jabatan === "") && <div className="alert-info">{info}</div>}
                                <div className="btn-text" onClick={handleUbah}>
                                    OK
                                </div>
                            </form>
                            <div className="close-btn" onClick={handleCancel}>
                                <i id="close-btn" className="fa fa-times" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    <div className="overlay"></div>
                </div>
            ) : null}
        </div>
    )
}

export default DataAdminPerangkat
