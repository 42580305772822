import React, { useContext } from 'react'
import axios from 'axios'
import { GlobalState } from '../../../context/GlobalContext'

const ListCarousel = ({ item, getDataCarousel }) => {

  const { baseUrl } = useContext(GlobalState)

  const deleteCarousel = () => {

    var config = {
      method: 'delete',
      url: `${baseUrl}/api/carousel/hapusCarousel.php?id=${item.id}`,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    axios(config)
      .then(response => {
        console.log(response.data.msg);
        getDataCarousel()
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div className="list-berita-container">
      <div className="image-list"><img src={`${item.imgUrl}`} alt={item.imgUrl} /></div>
      <div className="list-caption">{item.caption}</div>
      <div className="hapus-berita" onClick={deleteCarousel}>
        <i id="remove-list-berita" className="fa fa-trash" aria-hidden="true" title="Hapus Data" ></i> Hapus
      </div>
    </div>
  )
}

export default ListCarousel