import React, {useState, useContext} from 'react'
import axios from 'axios'
import {GlobalState} from '../../../context/GlobalContext'

const DataAdminAPBDesa = () => {

    const {baseUrl} = useContext(GlobalState)

    const [files, setFiles] = useState(null)
    const [info, setInfo] = useState(null)
    const [sukses, setSukses] = useState(null)

    const handleSubmit = (e) => {
        e.preventDefault()

        if(files!==null){
            var FormData = require('form-data');
            var dataBaru = new FormData();
            dataBaru.append('anyfile', files);
        
            var config = {
                method: 'post',
                url: `${baseUrl}/api/upload/uploadApbdesa.php`,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: dataBaru
            };
        
            axios(config)
                .then(function (response) {
                    if(response.data==="Your file was uploaded successfully."){
                        console.log(response.data)
                        setSukses(true)
                        setInfo(response.data)
                    }else{
                        setSukses(false)
                        setInfo(response.data)
                    }
                })
                .catch(function (error) {
                    console.log(error)
                    setSukses(false)
                    setInfo(error)
                });
        }
    }

    return (
        <>
            <span className="deskripsi-title">Data APB Desa</span>
            <div className="deskripsi">
                <span className="deskripsi-subtitle">Data Anggaran Pendapatan dan Belanja Desa</span>
                <span>Unggah file Anggaran Pendapatan dan Belanja Desa (APBDesa) :</span>
                <div className="unggah-container">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                        {sukses ? <span className="sukses">{info}</span>:<span className="gagal">{info}</span>}
                        <div>
                            <input type="file" defaultValue={files||null} onChange={(e)=>setFiles(e.target.files[0])} />
                        </div>
                        <span className="format-unggah">format data (.jpg/.jpeg/.png)</span>
                        <div className="btn-submit">
                            <input type="submit" value="Unggah" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default DataAdminAPBDesa