import React, {useEffect} from 'react'
import Header from '../../../components/header/Header'
import Copyright from '../../../components/copyright/Copyright'
import Media from '../../../components/media/Media'
import BannerTop from '../../../components/afiliasi/BannerTop'
import DataPenduduk from './DataPenduduk'

const Penduduk = () => {
    
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <>
            <Header />
            <BannerTop />
            <DataPenduduk />
            <Media />
            <Copyright />
        </>
    )
}

export default Penduduk
