import React, { useState, useEffect, useContext } from 'react'
import {GlobalState} from '../../../context/GlobalContext'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ListBerita from './ListBerita'

const DataAdminBerita = () => {

    const {baseUrl} = useContext(GlobalState)

    const [dataBerita, setDataBerita] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const urlBerita = `${baseUrl}/api/berita/berita.php`

    const getDataBerita = () => {
        setIsLoading(true)
        axios.get(urlBerita)
            .then(result => {
                setDataBerita(result.data.reverse())
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getDataBerita()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <div className="data-admin-deskripsi">
            <h3>Halaman Berita</h3>
            <span>Berikut merupakan halaman yang menampilkan data berita. Silahkan Klik tambah untuk menambahkan berita :</span>
            <div className="menu-header">
                <div className="list-berita-subtitle">Daftar Berita</div>
                <div className="tambah-berita-icon">
                    <Link to="/tambah-berita">
                        Tambah
                    </Link>
                </div>
            </div>
            {
                dataBerita !== [] && (
                    dataBerita.map((item) => {
                        return (
                            <ListBerita key={item.id} item={item} getDataBerita={getDataBerita} />
                        )
                    })
                )
            }
            {
                isLoading && <span style={{ fontSize: 9, textAlign: 'center' }}>Loading...</span>
            }
        </div>
    )
}

export default DataAdminBerita
