import React, { useState, useEffect, useContext } from 'react'
import { GlobalState } from '../../../context/GlobalContext'
import axios from 'axios'

const DataUbahMediaSosial = () => {
    const { baseUrl } = useContext(GlobalState)

    const [data, setData] = useState({
        facebook: "",
        twitter: "",
        instagram: "",
        youtube: ""
    })

    const {
        facebook, twitter, instagram, youtube 
    } = data

    const apiSocialMedia = `${baseUrl}/api/media/media.php`

    // get latest data social media
    const getLatestSocialMediaData = async () => {
        await axios.get(apiSocialMedia)
            .then(res => {
                setData({
                    ...data,
                    facebook: res.data[0]?.facebook,
                    twitter: res.data[0]?.twitter,
                    instagram: res.data[0]?.instagram,
                    youtube: res.data[0]?.youtube,
                })
            })
    }

    useEffect(() => {
        getLatestSocialMediaData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ubah auth
    const ubahSocialMedia = () => {

        const FormData = require('form-data');
        const data = new FormData();
        data.append('facebook', facebook);
        data.append('twitter', twitter);
        data.append('instagram', instagram);
        data.append('youtube', youtube);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/media/ubahMedia.php?id=1`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        axios(config)
            .then(response => {
                console.log(response.data);
                window.scrollTo(0, 0)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
            <span className="deskripsi-title">Data Ubah sosial media</span>
            <div className="deskripsi">
                <span className="deskripsi-subtitle">Ubah informasi sosial media di sini:</span>
                <div className="unggah-container">
                    <form onSubmit={ubahSocialMedia}>
                        <div className="item-data">
                            <span>Facebook :</span>
                            <input type="text" name="facebook" value={facebook} onChange={(e) => setData({ ...data, facebook: e.target.value })} />
                        </div>
                        <div className="item-data">
                            <span>Twitter :</span>
                            <input type="text" name="twitter" value={twitter} onChange={(e) => setData({ ...data, twitter: e.target.value })} />
                        </div>
                        <div className="item-data">
                            <span>Instagram :</span>
                            <input type="text" name="instagram" value={instagram} onChange={(e) => setData({ ...data, instagram: e.target.value })} />
                        </div>
                        <div className="item-data">
                            <span>Youtube :</span>
                            <input type="text" name="youtube" value={youtube} onChange={(e) => setData({ ...data, youtube: e.target.value })} />
                        </div>
                        <div className="btn-submit">
                            <input type="submit" value="Ubah" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default DataUbahMediaSosial