import React, {useEffect} from 'react'
import Header from '../../components/header/Header'
import Copyright from '../../components/copyright/Copyright'
import Media from '../../components/media/Media'
import BannerTop from '../../components/afiliasi/BannerTop'
import DataPasar from '../../components/data-pasar/DataPasar'
import BannerBottom from '../../components/afiliasi/BannerBottom'

const Pasar = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <>
            <Header />
            <BannerTop />
            <DataPasar />
            <BannerBottom />
            <Media />
            <Copyright />
        </>
    )
}

export default Pasar
