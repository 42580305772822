import React, {useEffect} from 'react'
import Copyright from '../../components/copyright/Copyright'
import Media from '../../components/media/Media'
import BannerTop from '../../components/afiliasi/BannerTop'
import BannerBottom from '../../components/afiliasi/BannerBottom'
import HeaderAdmin from '../../components/header/HeaderAdmin'
import './Admin.css'
import DataDetailWarga from '../../components/data-admin/kependudukan/DataDetailWarga'

const InfoDetailWarga = ({detailedData}) => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <>
            <HeaderAdmin />
            <BannerTop />
            <DataDetailWarga detailedData={detailedData} />
            <BannerBottom />
            <Media />
            <Copyright />
        </>
    )
}

export default InfoDetailWarga
