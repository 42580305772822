import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ListProduk from './ListProduk'
import {GlobalState} from '../../../context/GlobalContext'

const DataAdminProduk = () => {

    const {baseUrl} = useContext(GlobalState)

    const [dataProduk, setDataProduk] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const urlProduk = `${baseUrl}/api/produk/produk.php`

    const getDataProduk = () => {
        setIsLoading(true)
        axios.get(urlProduk)
            .then(result => {
                setDataProduk(result.data.reverse())
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getDataProduk()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <div className="data-admin-deskripsi">
            <h3>Halaman Produk</h3>
            <span>Berikut merupakan halaman yang menampilkan data produk. Silahkan Klik tombol tambah untuk menambahkan produk :</span>
            <div className="menu-header">
                <div className="tambah-produk-icon">
                    <Link to="/tambah-produk">
                        Tambah
                    </Link>
                </div>
            </div>
            <div className="list-produk-subtitle">Daftar Produk</div>
            {
                dataProduk.length > 0 && (
                    dataProduk.map((item) => {
                        return (
                            <ListProduk key={item.id} item={item} getDataProduk={getDataProduk} />
                        )
                    })
                )
            }
            {
                isLoading && <span style={{ fontSize: 9, textAlign: 'center' }}>Loading...</span>
            }
            {
                !isLoading && dataProduk.length === 0 && (
                    <span style={{ fontSize: 11, textAlign: "center", color: "black" }}>Belum Ada Daftar Produk</span>
                )
            }
        </div>
    )
}

export default DataAdminProduk
