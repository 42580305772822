import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { GlobalState } from '../../../context/GlobalContext'

const ListPenduduk = ({ item, infoDetailed, getDataPenduduk }) => {

    const { baseUrl } = useContext(GlobalState)

    const handleInfoDetailed = () => {
        infoDetailed(item)
    }

    const deleteWarga = () => {

        var config = {
            method: 'delete',
            url: `${baseUrl}/api/penduduk/hapusPenduduk.php?nik=${item.nik}`,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        axios(config)
            .then(response => {
                console.log(response.data.msg);
                getDataPenduduk()
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <tr onClick={handleInfoDetailed}>
            <td className="col-nama">{item.nama}</td>
            <td className="col-nik">{item.nik}</td>
            <td className="col-aksi">
                <div className="action">
                    <div className="action-btn">
                        <Link to='info-data-penduduk'>
                            <i id="icon-edit" className="fa fa-pencil-square-o" aria-hidden="true" title="Edit Data" ></i>
                        </Link>
                    </div>
                    <div className="action-btn">
                        <i id="icon-remove" className="fa fa-trash" aria-hidden="true" title="Hapus Data" onClick={deleteWarga} ></i>
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default ListPenduduk
