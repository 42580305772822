import React, { useState, useEffect, useContext } from 'react'
import { GlobalState } from '../../../context/GlobalContext'
import axios from 'axios'

const DataUbahAuthentifikasi = () => {

    const { baseUrl } = useContext(GlobalState)

    const [data, setData] = useState({
        username: "",
        password: ""
    })

    const {
        username, password
    } = data

    const apiAuth = `${baseUrl}/api/auth/auth.php`

    // get latest data auth
    const getLatestAuth = async () => {
        await axios.get(apiAuth)
            .then(res => {
                setData({
                    ...data,
                    username: res.data[0]?.username,
                    password: res.data[0]?.password
                })
            })
    }

    useEffect(() => {
        getLatestAuth()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ubah auth
    const ubahAuth = () => {

        const FormData = require('form-data');
        const data = new FormData();
        data.append('username', username);
        data.append('password', password);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/auth/ubahAuth.php?id=1`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        axios(config)
            .then(response => {
                console.log(response.data);
                window.scrollTo(0, 0)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
            <span className="deskripsi-title">Data Ubah username dan password</span>
            <div className="deskripsi">
                <span className="deskripsi-subtitle">Ubah informasi username dan password di sini:</span>
                <div className="unggah-container">
                    <form onSubmit={ubahAuth}>
                        <div className="item-data">
                            <span>Username :</span>
                            <input type="text" name="username" value={username} onChange={(e) => setData({ ...data, username: e.target.value })} />
                        </div>
                        <div className="item-data">
                            <span>Password :</span>
                            <input type="text" name="password" value={password} onChange={(e) => setData({ ...data, password: e.target.value })} />
                        </div>
                        <div className="btn-submit">
                            <input type="submit" value="Ubah" />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default DataUbahAuthentifikasi