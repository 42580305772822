import { createContext, useState } from 'react'

// initial state
const pemdes = {
    id: "1",
    desa: "Mekarjaya",
    kecamatan: "Cidolog",
    kabupaten: "Sukabumi",
    propinsi: "Jawa Barat",
    kodePos: "43184",
    website: "www.mekarjaya.desaku.app",
    alamat: "Jl. Raya Cidolog No. 43, Cileutik, RT.002/RW.001",
    periode: "2019/2025",
    fb: "-",
    twitter: "-",
    instagram: "-",
    youtube: "-"
}

// create GlobalState context
export const GlobalState = createContext(pemdes)

// create GlobalProvider
export const GlobalProvider = ({ children }) => {

    //Authentication
    const [authenticated, setAuthenticated] = useState(false)

    //info user untuk surat elektronik
    const [identitas, setIdentitas] = useState({
        nama: '',
        nik: '',
        tempat: '',
        tanggalLahir: '',
        agama: '',
        jenisKelamin: '',
        statusPerkawinan: '',
        alamat: ''
    })

    // sidebar dan overlay
    const [sidebar, setSidebar] = useState(false)

    // ubah data produk umkm
    const [produkUmkm, setProdukUmkm] = useState(null)

    // ubah data lembaga
    const [dataLembaga, setDataLembaga] = useState([])

    // ubah data aset
    const [dataAset, setDataAset] = useState([])

    // base url
    const baseUrl = "https://mekarjaya.desaku.app"
    
    return (
        <GlobalState.Provider value={{ pemdes, authenticated, setAuthenticated, identitas, setIdentitas, sidebar, setSidebar, produkUmkm, setProdukUmkm, baseUrl, dataLembaga, setDataLembaga, dataAset, setDataAset }}>
            {children}
        </GlobalState.Provider>
    )
}