import React, { useState, useEffect, useContext } from 'react'
import { GlobalState } from '../../context/GlobalContext'
import axios from 'axios'
import './DataLembaga.css'

const DataLembaga = () => {

    const { pemdes, baseUrl } = useContext(GlobalState)

    const [dataLembaga, setDataLembaga] = useState([])

    const apiLembaga = `${baseUrl}/api/lembaga/lembaga.php`

    const getApiLembaga = async () => {
        await axios.get(apiLembaga)
            .then(res => setDataLembaga(res.data))
    }

    useEffect(() => {
        getApiLembaga()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="data-lembaga-container">
            <h3>Data Lembaga Desa {pemdes.desa}</h3>
            <span>Berikut merupakan beberapa lembaga desa yang dimiliki oleh Desa {pemdes.desa}:</span>
            <div className="deskripsi">
                {
                    dataLembaga.length > 0 && (
                        dataLembaga.map((res, index) => {
                            return (
                                <div className="detail" key={res.id}>
                                    <span className="lembaga-title">{index + 1}. {res.title}</span>
                                    <div className="lembaga-gambar">
                                        <img src={res.imgUrl} alt={`${res.title}`} />
                                    </div>
                                    <span className="desc">{res.text1}</span>
                                    <span className="desc">{res.text2}</span>
                                    <span className="desc">{res.text3}</span>
                                </div>
                            )
                        })
                    )
                }
            </div>
        </div>
    )
}

export default DataLembaga