import React from 'react'
import { Link } from 'react-router-dom'

const DetailBerita = ({ news, detailItemBerita }) => {

    const pilihBerita = () => {
        detailItemBerita(news)
    }

    return (
        <Link to='/berita'>
            <div className="berita-item" onClick={pilihBerita} >
                <div className="berita-image">
                    <img src={news.imgUrl} alt="berita" />
                </div>
                <div className="judul-berita">
                    <span>{news.title}</span>
                </div>
            </div>
        </Link>
    )
}

export default DetailBerita
