import React from 'react'
import './Berita.css'
import DetailBerita from './DetailBerita'

const Berita = ({berita, detailItemBerita}) => {
    return (
        <div className="berita-container">
            <h3>Berita Terbaru</h3>
            <div className="berita">
                {
                    berita.reverse().slice(0,6).map(news=>{
                        return(
                            <DetailBerita news={news} detailItemBerita={detailItemBerita} key={news.id} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Berita
