import React, { useState, useContext } from 'react'
import {useHistory} from 'react-router-dom'
import axios from 'axios'
import {GlobalState} from '../../../context/GlobalContext'

const DataDetailWarga = ({ detailedData }) => {

    const {baseUrl} = useContext(GlobalState)

    const [nama, setNama] = useState(`${detailedData.nama}`)
    const [nik, setNik] = useState(`${detailedData.nik}`)
    const [noKk, setNoKk] = useState(`${detailedData.no_kk}`)
    const [jenisKelamin, setJenisKelamin] = useState(`${detailedData.jenis_kelamin}`)
    const [tempatLahir, setTempatLahir] = useState(`${detailedData.tempat_lahir}`)
    const [tglLahir, setTglLahir] = useState(`${detailedData.tgl_lahir}`)
    const [agama, setAgama] = useState(`${detailedData.agama}`)
    const [pendidikan, setPendidikan] = useState(`${detailedData.pendidikan}`)
    const [pekerjaan, setPekerjaan] = useState(`${detailedData.pekerjaan}`)
    const [statusPerkawinan, setStatusPerkawinan] = useState(`${detailedData.status_perkawinan}`)
    const [kewarganegaraan, setKewarganegaraan] = useState(`${detailedData.kewarganegaraan}`)
    const [alamat, setAlamat] = useState(`${detailedData.alamat}`)
    const [rt, setRt] = useState(`${detailedData.rt}`)
    const [rw, setRw] = useState(`${detailedData.rw}`)
    const [kelurahan, setKelurahan] = useState(`${detailedData.kelurahan}`)
    const [kecamatan, setKecamatan] = useState(`${detailedData.kecamatan}`)
    const [kabupaten, setKabupaten] = useState(`${detailedData.kota}`)
    const [propinsi, setPropinsi] = useState(`${detailedData.propinsi}`)
    const [kodePos, setKodePos] = useState(`${detailedData.kode_pos}`)

    var history = useHistory()

    const handleUpdate = () => {
        
        var FormData = require('form-data');
        var data = new FormData();
        data.append('nik', nik);
        data.append('noKk', noKk);
        data.append('nama', nama);
        data.append('jenisKelamin', jenisKelamin);
        data.append('tempatLahir', tempatLahir);
        data.append('tglLahir', tglLahir);
        data.append('agama', agama);
        data.append('pendidikan', pendidikan);
        data.append('pekerjaan', pekerjaan);
        data.append('statusPerkawinan', statusPerkawinan);
        data.append('kewarganegaraan', kewarganegaraan);
        data.append('alamat', alamat);
        data.append('rt', rt);
        data.append('rw', rw);
        data.append('kelurahan', kelurahan);
        data.append('kecamatan', kecamatan);
        data.append('kabupaten', kabupaten);
        data.append('propinsi', propinsi);
        data.append('kodePos', kodePos);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/penduduk/ubahPenduduk.php?nik=${nik}`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        axios(config)
            .then(response=>{
                console.log(response.data.msg)
                history.push('/data-penduduk')
                window.scrollTo(0, 0)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="detail-warga-container">
            <h3>Info Data Penduduk</h3>
            {
                detailedData !== [] && (
                    <div className="data-warga">
                        <form>
                            <div className="item-data">
                                <span>Nama :</span>
                                <input type="text" name="nama" defaultValue={nama || ''} onChange={(e) => setNama(e.target.value)} />
                            </div>
                            <div className="item-data">
                                <span>NIK :</span>
                                <input type="text" name="nik" defaultValue={nik || ''} onChange={(e) => setNik(e.target.value)} disabled />
                            </div>
                            <div className="item-data">
                                <span>No. Kartu Keluarga :</span>
                                <input type="text" name="no_kk" defaultValue={noKk || ''} onChange={(e) => setNoKk(e.target.value)} disabled />
                            </div>
                            <div className="item-data">
                                <span>Jenis Kelamin :</span>
                                <input type="text" name="jenis_kelamin" defaultValue={jenisKelamin || ''} onChange={(e) => setJenisKelamin(e.target.value)} />
                            </div>
                            <div className="item-data">
                                <span>Tempat Lahir :</span>
                                <input type="text" name="tempat_lahir" defaultValue={tempatLahir || ''} onChange={(e) => setTempatLahir(e.target.value)} />
                            </div>
                            <div className="item-data">
                                <span>Tanggal Lahir :</span>
                                <input type="text" name="tgl_lahir" defaultValue={tglLahir || ''} onChange={(e) => setTglLahir(e.target.value)} />
                            </div>
                            <div className="item-data">
                                <span>Agama :</span>
                                <input type="text" name="agama" defaultValue={agama || ''} onChange={(e) => setAgama(e.target.value)} />
                            </div>
                            <div className="item-data">
                                <span>Pendidikan :</span>
                                <input type="text" name="pendidikan" defaultValue={pendidikan || ''} onChange={(e) => setPendidikan(e.target.value)} />
                            </div>
                            <div className="item-data">
                                <span>Pekerjaan :</span>
                                <input type="text" name="pekerjaan" defaultValue={pekerjaan || ''} onChange={(e) => setPekerjaan(e.target.value)} />
                            </div>
                            <div className="item-data">
                                <span>Status Perkawinan :</span>
                                <input type="text" name="status_perkawinan" defaultValue={statusPerkawinan || ''} onChange={(e) => setStatusPerkawinan(e.target.value)} />
                            </div>
                            <div className="item-data">
                                <span>Kewarganegaraan :</span>
                                <input type="text" name="kewarganegaraan" defaultValue={kewarganegaraan || ''} onChange={(e) => setKewarganegaraan(e.target.value)} />
                            </div>
                            <div className="item-data">
                                <span>Alamat :</span>
                                <input type="text" name="alamat" defaultValue={alamat || ''} onChange={(e) => setAlamat(e.target.value)} />
                            </div>
                            <div className="item-data">
                                <span>RT :</span>
                                <input type="text" name="rt" defaultValue={rt || ''} onChange={(e) => setRt(e.target.value)} />
                            </div>
                            <div className="item-data">
                                <span>RW :</span>
                                <input type="text" name="rw" defaultValue={rw || ''} onChange={(e) => setRw(e.target.value)} />
                            </div>
                            <div className="item-data">
                                <span>Kelurahan :</span>
                                <input type="text" name="kelurahan" defaultValue={kelurahan || ''} onChange={(e) => setKelurahan(e.target.value)} />
                            </div>
                            <div className="item-data">
                                <span>Kecamatan :</span>
                                <input type="text" name="kecamatan" defaultValue={kecamatan || ''} onChange={(e) => setKecamatan(e.target.value)} />
                            </div>
                            <div className="item-data">
                                <span>Kabupaten :</span>
                                <input type="text" name="kabupaten" defaultValue={kabupaten || ''} onChange={(e) => setKabupaten(e.target.value)} />
                            </div>
                            <div className="item-data">
                                <span>Propinsi :</span>
                                <input type="text" name="propinsi" defaultValue={propinsi || ''} onChange={(e) => setPropinsi(e.target.value)} />
                            </div>
                            <div className="item-data">
                                <span>Kode Pos :</span>
                                <input type="text" name="kode_pos" defaultValue={kodePos || ''} onChange={(e) => setKodePos(e.target.value)} />
                            </div>
                            <div className="update-data-warga-btn">
                                <span onClick={handleUpdate} >Update</span>
                            </div>
                        </form>
                    </div>
                )
            }
        </div>
    )
}

export default DataDetailWarga
