import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { GlobalState } from '../../../context/GlobalContext'

const DataUbahProduk = () => {

    const { produkUmkm, baseUrl } = useContext(GlobalState)

    const [data, setData] = useState({
        id: produkUmkm.id,
        name: produkUmkm.name,
        price: produkUmkm.price,
        price2: produkUmkm.price2,
        stok: produkUmkm.stok,
        rate: produkUmkm.rate,
        phone: produkUmkm.phone
    })

    const { id, name, price, price2, stok, rate, phone } = data

    const [file, setFile] = useState(produkUmkm.imgUrl)

    var history = useHistory()

    const uploadImageProduk = () => {
        const FormData = require('form-data');
        const dataImage = new FormData();
        dataImage.append('anyfile', file);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/upload/uploadImageProduk.php`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: dataImage
        };

        axios(config)
            .then(function (response) {
                console.log(response.data)
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const ubahProduk = () => {

        uploadImageProduk()

        const FormData = require('form-data');
        const dataProduk = new FormData();
        dataProduk.append('name', name);
        dataProduk.append('price', price);
        dataProduk.append('price2', price2);
        dataProduk.append('stok', stok);
        dataProduk.append('imgUrl', `${baseUrl}/images/produk/` + file.name);
        dataProduk.append('rate', rate);
        dataProduk.append('phone', phone);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/produk/ubahProduk.php?id=${id}`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: dataProduk
        };

        axios(config)
            .then(response => {
                console.log(response.data);
                history.push('/admin-produk')
                window.scrollTo(0, 0)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="tambah-warga-container">
            <h3>Halaman Ubah Data Produk</h3>
            <div className="deskripsi-tambah-warga">
                <span>Silahkan Isi form berikut untuk mengubah data produk :</span>
            </div>
            <div className="data-warga">
                <form>
                    <div className="item-data">
                        <span>Nama Produk :</span>
                        <input type="text" value={name} name="name" onChange={(e) => setData({ ...data, name: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Harga Bawah:</span>
                        <input type="number" value={price} name="price" onChange={(e) => setData({ ...data, price: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Harga Atas:</span>
                        <input type="number" value={price2} name="price2" onChange={(e) => setData({ ...data, price2: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Stok :</span>
                        <input type="number" value={stok} name="stok" onChange={(e) => setData({ ...data, stok: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Upload Produk :</span>
                        <input type="file" name="imgUrl" onChange={(e) => setFile(e.target.files[0])} />
                        <span style={{ fontSize: 9, fontWeight: "bold", paddingLeft: 8 }}>format data (.jpg/.jpeg/.png)</span>
                    </div>
                    <div className="item-data">
                        <span>Rating :</span>
                        <input type="number" value={rate} name="rate" onChange={(e) => setData({ ...data, rate: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Phone :</span>
                        <input type="text" value={phone} name="text" onChange={(e) => setData({ ...data, phone: e.target.value })} />
                    </div>
                    <div className="update-data-warga-btn" onClick={ubahProduk}>
                        <span>Ubah</span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DataUbahProduk
