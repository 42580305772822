import React, {useEffect} from 'react'
import Header from '../../../components/header/Header'
import Copyright from '../../../components/copyright/Copyright'
import Media from '../../../components/media/Media'
import BannerTop from '../../../components/afiliasi/BannerTop'
import DataSkPenghasilan from './DataSkPenghasilan'

const SkPenghasilan = ({setNama, setTempat, setTanggal, setJenisKelamin, setAgama, setStatusPerkawinan, setNik, setAlamat }) => {
    
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <>
            <Header />
            <BannerTop />
            <DataSkPenghasilan setNama={setNama} setTempat={setTempat} setTanggal={setTanggal} setJenisKelamin={setJenisKelamin} setAgama={setAgama} setStatusPerkawinan={setStatusPerkawinan} setNik={setNik} setAlamat={setAlamat} />
            <Media />
            <Copyright />
        </>
    )
}

export default SkPenghasilan
