import React, { useState, useContext } from 'react'
import { GlobalState } from '../../../context/GlobalContext'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

const DataTambahAset = () => {

    const { baseUrl } = useContext(GlobalState)

    const [data, setData] = useState({
        title: "",
        desc: ""
    })

    const { title, desc } = data

    const [file, setFile] = useState(null)

    var history = useHistory()

    const uploadImageAset = () => {
        const FormData = require('form-data');
        const dataBaru = new FormData();
        dataBaru.append('anyfile', file);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/upload/uploadImageAset.php`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: dataBaru
        };

        axios(config)
            .then(function (response) {
                console.log(response.data)
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const tambahAset = () => {

        uploadImageAset()

        const FormData = require('form-data');
        const data = new FormData();
        data.append('title', title);
        data.append('imgUrl', `${baseUrl}/images/aset/` + file.name);
        data.append('desc', desc);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/aset/tambahAset.php`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        axios(config)
            .then(response => {
                console.log(response.data);
                history.push('/admin-aset')
                window.scrollTo(0, 0)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="tambah-warga-container">
            <h3>Halaman Tambah Data Aset</h3>
            <div className="deskripsi-tambah-warga">
                <span>Silahkan Isi form berikut untuk menambahkan aset :</span>
            </div>
            <div className="data-warga">
                <form>
                    <div className="item-data">
                        <span>Judul :</span>
                        <input type="text" name="title" onChange={(e) => setData({ ...data, title: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Upload Gambar :</span>
                        <input type="file" name="imgUrl" onChange={(e) => setFile(e.target.files[0])} />
                        <span style={{ fontSize: 9, fontWeight: "bold", paddingLeft: 8 }}>format data (.jpg/.jpeg/.png)</span>
                    </div>
                    <div className="item-data">
                        <span>Deskripsi :</span>
                        <textarea type="textarea" name="name" onChange={(e) => setData({ ...data, desc: e.target.value })} />
                    </div>
                    <div className="update-data-warga-btn" onClick={tambahAset}>
                        <span >Tambah</span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DataTambahAset