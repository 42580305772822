import React, { useState, useEffect, useContext } from 'react'
import { GlobalState } from '../../../context/GlobalContext'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ListCarousel from './ListCarousel'

const DataAdminCarousel = () => {

    const { baseUrl } = useContext(GlobalState)

    const [dataCarousel, setDataCarousel] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const urlCarousel = `${baseUrl}/api/carousel/carousel.php`

    const getDataCarousel = async () => {
        setIsLoading(true)
        await axios.get(urlCarousel)
            .then(result => {
                setDataCarousel(result.data.reverse())
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getDataCarousel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="data-admin-deskripsi">
            <h3>Halaman Carousel</h3>
            <span>Berikut merupakan halaman yang menampilkan data carousel. Silahkan Klik tambah untuk menambahkan carousel :</span>
            <div className="menu-header">
                <div className="list-berita-subtitle">Daftar Carousel</div>
                <div className="tambah-berita-icon">
                    <Link to="/tambah-carousel">
                        Tambah
                    </Link>
                </div>
            </div>
            {
                dataCarousel !== [] && (
                    dataCarousel.map((item) => {
                        return (
                            <ListCarousel key={item.id} item={item} getDataCarousel={getDataCarousel} />
                        )
                    })
                )
            }
            {
                isLoading && <span style={{ fontSize: 9, textAlign: 'center' }}>Loading...</span>
            }
        </div>
    )
}

export default DataAdminCarousel