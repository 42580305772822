import React, { useContext } from 'react'
import { GlobalState } from '../../context/GlobalContext'
import './Peta.css'

const Peta = () => {

    const { pemdes } = useContext(GlobalState)

    return (
        <div className="peta-container">
            <h3>Peta Desa {pemdes.desa}</h3>
            <div className="peta">
                <iframe title="Peta Desa Mekarjaya" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11336.513589239217!2d106.85048114625302!3d-7.300540666500948!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e680d9a3df76a7f%3A0xdaab69b125fa0b80!2sMekarjaya%2C%20Cidolog%2C%20Sukabumi%20Regency%2C%20West%20Java!5e1!3m2!1sen!2sid!4v1649406315858!5m2!1sen!2sid" width="100%" height="250" style={{ border: 0 }} loading="lazy"></iframe>
            </div>
        </div>
    )
}

export default Peta
