import React, { useEffect } from 'react'
import Copyright from '../../components/copyright/Copyright'
import Media from '../../components/media/Media'
import BannerTop from '../../components/afiliasi/BannerTop'
import BannerBottom from '../../components/afiliasi/BannerBottom'
import HeaderAdmin from '../../components/header/HeaderAdmin'
// import axios from 'axios'
import './Admin.css'
import DataTambahWarga from '../../components/data-admin/kependudukan/DataTambahWarga'

const TambahWarga = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <HeaderAdmin />
            <BannerTop />
            <DataTambahWarga />
            <BannerBottom />
            <Media />
            <Copyright />
        </>
    )
}

export default TambahWarga
