import React, { useState, useEffect, useContext } from 'react'
import './DataPapanInfo.css'
import axios from 'axios'
import { GlobalState } from '../../context/GlobalContext'

const PapanInfo = () => {

    const { baseUrl } = useContext(GlobalState)

    const [informasi, setInformasi] = useState(null)

    const ApiInformasiUrl = `${baseUrl}/api/papan/papanInformasi.php`

    const getInformasi = async () => {
        await axios.get(ApiInformasiUrl)
            .then(result => {
                setInformasi(result.data)
            })
    }

    useEffect(() => {
        getInformasi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="video-container">
            <h3>Informasi Terbaru</h3>
            {
                informasi !== null && (
                    <div className="papan-info">
                        <img src={`${baseUrl}/images/info/${informasi[0].file}`} alt="papan-info" />
                    </div>
                )
            }
        </div>
    )
}

export default PapanInfo
