import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import logo from '../../assets/icon/logo.png'
import Login from '../login/Login'
import userIcon from '../../assets/icon/user.png'
import backIcon from '../../assets/icon/left-arrow.png'
import './Header.css'

const Header = () => {

    const [waktu, setWaktu] = useState("")
    const [adminPopup, setAdminPopup] = useState(false)

    const greeting = () => {
        let time = new Date()
        let hours = time.getHours()

        if (hours >= 4 && hours < 10) {
            setWaktu("Selamat Pagi")
        } else if (hours >= 10 && hours < 14) {
            setWaktu("Selamat Siang")
        } else if (hours >= 14 && hours < 19) {
            setWaktu("Selamat Sore")
        } else {
            setWaktu("Selamat Malam")
        }
    }

    const openPopUpView = () => {
        setAdminPopup(true)
    }

    const closePopUpView = () => {
        setAdminPopup(!adminPopup)
    }

    let url = window.location
    let path = url.pathname.split('/')
    let page = path.pop().toUpperCase().replaceAll('-', ' ')

    let history = useHistory()
    
    const handleBackPage = () => {
        history.goBack()
        window.scrollTo(0,0)
    }

    useEffect(() => {
        greeting()
    }, [waktu])

    return (
        <div className="header-container">
            {page === "" ? (
                <div className="logo">
                    <img src={logo} alt="logo desaku.net" />
                </div>
            ) : (
                    <div className="logo" onClick={handleBackPage}>
                        <img className="fa fa-chevron-left" src={backIcon} alt="back" />
                    </div>
                )}
            <div className="page">
                {page === "" ? null : <span>{page}</span>}
            </div>
            {page === "" ? (
                <div className="personalisasi">
                    <span>{waktu}, User</span>
                    <img className="fa fa-user" src={userIcon} onClick={openPopUpView} alt="user" title="login" />
                </div>
            ) : (
                    <div className="personalisasi">
                        <img className="fa fa-user" src={userIcon} onClick={openPopUpView} alt="user" title="login" />
                    </div>
                )}
            {adminPopup ? <Login closePopUpView={closePopUpView} /> : null}
        </div>
    )
}

export default Header
