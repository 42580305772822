import React, { useState, useEffect, useContext } from 'react'
import { GlobalState } from '../../../context/GlobalContext'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ListAset from './ListAset'

const DataAdminAset = () => {

    const { baseUrl } = useContext(GlobalState)

    const [dataAset, setDataAset] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const urlAset = `${baseUrl}/api/aset/aset.php`

    const getDataAset = () => {
        setIsLoading(true)
        axios.get(urlAset)
            .then(result => {
                setDataAset(result.data.reverse())
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getDataAset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="data-admin-deskripsi">
            <h3>Halaman Aset</h3>
            <span>Berikut merupakan halaman yang menampilkan data aset. Silahkan Klik tambah untuk menambahkan aset :</span>
            <div className="menu-header">
                <div className="list-berita-subtitle">Daftar Aset</div>
                <div className="tambah-berita-icon">
                    <Link to="/tambah-aset">
                        Tambah
                    </Link>
                </div>
            </div>
            {
                dataAset !== [] && (
                    dataAset.map((item) => {
                        return (
                            <ListAset key={item.id} item={item} getDataAset={getDataAset} />
                        )
                    })
                )
            }
            {
                isLoading && <span style={{ fontSize: 9, textAlign: 'center' }}>Loading...</span>
            }
        </div>
    )
}

export default DataAdminAset