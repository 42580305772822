import React, { useState, useEffect, useContext } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Dashboard from './pages/dashboard/Dashboard'
import ProfilDesa from './pages/profil/ProfilDesa'
import RkpDesa from './pages/rkpdesa/RkpDesa'
import DataAsetDesa from './pages/aset/DataAsetDesa'
import APBDesa from './pages/apbdesa/APBDesa'
import Kritik from './pages/kritik/Kritik'
import Infografis from './pages/infografis/Infografis'
import HalamanBerita from './pages/berita/HalamanBerita'
import GaleriProduk from './pages/pasar/GaleriProduk'
import SuratElektronik from './pages/surat/SuratElektronik'
import Domisili from './pages/surat/domisili/Domisili'
import CetakDomisili from './pages/surat/domisili/CetakDomisili'
import Skck from './pages/surat/skck/Skck'
import CetakSkck from './pages/surat/skck/CetakSkck'
import Sktm from './pages/surat/sktm/Sktm'
import CetakSktm from './pages/surat/sktm/CetakSktm'
import SkPenghasilan from './pages/surat/penghasilan/SkPenghasilan'
import CetakSkPenghasilan from './pages/surat/penghasilan/CetakSkPenghasilan'
import SkKehilangan from './pages/surat/kehilangan/SkKehilangan'
import CetakSkKehilangan from './pages/surat/kehilangan/CetakSkKehilangan'
import Penduduk from './pages/surat/penduduk/Penduduk'
import CetakPenduduk from './pages/surat/penduduk/CetakPenduduk'
import Usaha from './pages/surat/usaha/Usaha'
import CetakUsaha from './pages/surat/usaha/CetakUsaha'
import Skdu from './pages/surat/skdu/Skdu'
import CetakSkdu from './pages/surat/skdu/CetakSkdu'
import SkUmum from './pages/surat/skumum/SkUmum'
import CetakSkUmum from './pages/surat/skumum/CetakSkUmum'
import PengantarUmum from './pages/surat/pengantar-umum/PengantarUmum'
import CetakPengantarUmum from './pages/surat/pengantar-umum/CetakPengantarUmum'
// import Papan from './pages/papan-info/Papan'
import AdminHome from './pages/admin/AdminHome'
import DataPenduduk from './pages/admin/DataPenduduk'
import InfoDetailWarga from './pages/admin/InfoDetailWarga'
import TambahWarga from './pages/admin/TambahWarga'
import Arsip from './pages/admin/Arsip'
import AdminBerita from './pages/admin/AdminBerita'
import TambahBerita from './pages/admin/TambahBerita'
import AdminProduk from './pages/admin/AdminProduk'
import TambahProduk from './pages/admin/TambahProduk'
import UbahProduk from './pages/admin/UbahProduk'
import Lembaga from './pages/lembaga/Lembaga'
import axios from 'axios'
import { GlobalState } from './context/GlobalContext'
import './App.css'
import AdminLembaga from './pages/admin/AdminLembaga'
import TambahLembaga from './pages/admin/TambahLembaga'
import UbahProfil from './pages/admin/UbahProfil'
import AdminAset from './pages/admin/AdminAset'
import TambahAset from './pages/admin/TambahAset'
import AdminCarousel from './pages/admin/AdminCarousel'
import TambahCarousel from './pages/admin/TambahCarousel'
import UbahLembaga from './pages/admin/UbahLembaga'
import UbahAset from './pages/admin/UbahAset'
import AdminSurat from './pages/admin/AdminSurat'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const App = () => {

  //Authentication
  const { baseUrl, authenticated } = useContext(GlobalState)

  // Notifikasi perpanjangan layanan
  const [open, setOpen] = useState(true)

  //url api
  const apiNews = `${baseUrl}/api/berita/berita.php`
  const apiPerangkat = `${baseUrl}/api/perangkat/perangkat.php`

  //get all data api
  const getApi = async () => {
    const getApiNews = axios.get(apiNews)
    const getApiPerangkat = axios.get(apiPerangkat)

    await axios.all([getApiNews, getApiPerangkat]).then(
      axios.spread((...allData) => {
        const allNewsData = allData[0].data
        const allPerangkatData = allData[1].data

        setBerita(allNewsData)
        setPerangkat(allPerangkatData)
      })
    )
  }

  //berita
  const [berita, setBerita] = useState([])
  const [detailBerita, setDetailBerita] = useState(null)

  const detailItemBerita = (item) => {
    setDetailBerita(item)
  }

  //data perangkat desa
  const [perangkat, setPerangkat] = useState([])

  ///info detail penduduk
  const [detailedData, setDetailedData] = useState([])

  const infoDetailed = (penduduk) => {
    setDetailedData(penduduk)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getApi()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path='/dashboard-admin' render={() => (authenticated ? <AdminHome /> : <Redirect to='/' />)} />
          <Route path='/arsip-surat' render={() => (authenticated ? <Arsip /> : <Redirect to='/' />)} />
          <Route path='/pengaturan-surat' render={() => (authenticated ? <AdminSurat /> : <Redirect to='/' />)} />
          <Route path='/data-penduduk' render={() => (authenticated ? <DataPenduduk infoDetailed={infoDetailed} /> : <Redirect to='/' />)} />
          <Route path='/info-data-penduduk' render={() => (authenticated ? <InfoDetailWarga detailedData={detailedData} /> : <Redirect to='/' />)} />
          <Route path='/tambah-warga' render={() => (authenticated ? <TambahWarga /> : <Redirect to='/' />)} />
          <Route path='/admin-berita' render={() => (authenticated ? <AdminBerita /> : <Redirect to='/' />)} />
          <Route path='/tambah-berita' render={() => (authenticated ? <TambahBerita /> : <Redirect to='/' />)} />
          <Route path='/admin-produk' render={() => (authenticated ? <AdminProduk /> : <Redirect to='/' />)} />
          <Route path='/tambah-produk' render={() => (authenticated ? <TambahProduk /> : <Redirect to='/' />)} />
          <Route path='/ubah-produk' render={() => (authenticated ? <UbahProduk /> : <Redirect to='/' />)} />
          <Route path='/admin-lembaga' render={() => (authenticated ? <AdminLembaga /> : <Redirect to='/' />)} />
          <Route path='/tambah-lembaga' render={() => (authenticated ? <TambahLembaga /> : <Redirect to='/' />)} />
          <Route path='/ubah-lembaga' render={() => (authenticated ? <UbahLembaga /> : <Redirect to='/' />)} />
          <Route path='/ubah-profil' render={() => (authenticated ? <UbahProfil /> : <Redirect to='/' />)} />
          <Route path='/admin-aset' render={() => (authenticated ? <AdminAset /> : <Redirect to='/' />)} />
          <Route path='/tambah-aset' render={() => (authenticated ? <TambahAset /> : <Redirect to='/' />)} />
          <Route path='/ubah-aset' render={() => (authenticated ? <UbahAset /> : <Redirect to='/' />)} />
          <Route path='/admin-carousel' render={() => (authenticated ? <AdminCarousel /> : <Redirect to='/' />)} />
          <Route path='/tambah-carousel' render={() => (authenticated ? <TambahCarousel /> : <Redirect to='/' />)} />
          <Route exact path='/' render={() => <Dashboard berita={berita} detailItemBerita={detailItemBerita} />} />
          <Route path='/profil' render={() => <ProfilDesa />} />
          <Route path='/rkpdesa' render={() => <RkpDesa />} />
          <Route path='/aset' render={() => <DataAsetDesa />} />
          <Route path='/lembaga' render={() => <Lembaga />} />
          <Route path='/apbdesa' render={() => <APBDesa />} />
          <Route path='/kritik' render={() => <Kritik />} />
          {/* <Route path='/papan-informasi' render={() => <Papan />} /> */}
          <Route path='/monografi' render={() => <Infografis />} />
          <Route path='/berita' render={() => <HalamanBerita detailBerita={detailBerita} berita={berita} detailItemBerita={detailItemBerita} />} />
          <Route exact path='/galeri-produk' render={() => <GaleriProduk />} />
          <Route exact path='/surat-elektronik' render={() => <SuratElektronik />} />
          <Route exact path='/surat/keterangan-domisili' render={() => <Domisili />} />
          <Route path='/surat/keterangan-domisili/cetak-surat' render={() => <CetakDomisili perangkat={perangkat} />} />
          <Route exact path='/surat/pengantar-skck' render={() => <Skck />} />
          <Route path='/surat/pengantar-skck/cetak-surat' render={() => <CetakSkck perangkat={perangkat} />} />
          <Route exact path='/surat/keterangan-tidak-mampu' render={() => <Sktm />} />
          <Route path='/surat/keterangan-tidak-mampu/cetak-surat' render={() => <CetakSktm perangkat={perangkat} />} />
          <Route exact path='/surat/keterangan-penghasilan' render={() => <SkPenghasilan />} />
          <Route path='/surat/keterangan-penghasilan/cetak-surat' render={() => <CetakSkPenghasilan perangkat={perangkat} />} />
          <Route exact path='/surat/keterangan-kehilangan' render={() => <SkKehilangan />} />
          <Route path='/surat/keterangan-kehilangan/cetak-surat' render={() => <CetakSkKehilangan perangkat={perangkat} />} />
          <Route exact path='/surat/keterangan-penduduk' render={() => <Penduduk />} />
          <Route path='/surat/keterangan-penduduk/cetak-surat' render={() => <CetakPenduduk perangkat={perangkat} />} />
          <Route exact path='/surat/keterangan-usaha' render={() => <Usaha />} />
          <Route path='/surat/keterangan-usaha/cetak-surat' render={() => <CetakUsaha perangkat={perangkat} />} />
          <Route exact path='/surat/keterangan-domisili-usaha' render={() => <Skdu />} />
          <Route path='/surat/keterangan-domisili-usaha/cetak-surat' render={() => <CetakSkdu perangkat={perangkat} />} />
          <Route exact path='/surat/keterangan-umum' render={() => <SkUmum />} />
          <Route path='/surat/keterangan-umum/cetak-surat' render={() => <CetakSkUmum perangkat={perangkat} />} />
          <Route exact path='/surat/pengantar-umum' render={() => <PengantarUmum />} />
          <Route path='/surat/pengantar-umum/cetak-surat' render={() => <CetakPengantarUmum perangkat={perangkat} />} />
        </Switch>
      </Router>
      
      {
                open && (
                    <Dialog
                        open={open}
                        onClose={() => setOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Notifikasi Perpanjangan Layanan
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Domain {baseUrl.replace(/^https:\/\//, '')} akan kadaluarsa pada tanggal 20-11-2023. Segera lakukan perpanjangan untuk dapat terus menggunakan layanan. Terimakasih !!
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpen(false)} autoFocus>
                                Tutup
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
            }
    </div>
  );
}

export default App;
