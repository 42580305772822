import React, {useState, useEffect} from 'react'
import Header from '../../components/header/Header'
import Copyright from '../../components/copyright/Copyright'
import Media from '../../components/media/Media'
import BannerTop from '../../components/afiliasi/BannerTop'
import DataSaran from '../../components/data-saran/DataSaran'
import BannerBottom from '../../components/afiliasi/BannerBottom'

const Kritik = () => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const [nama, setNama] = useState('')
    const [nik, setNik] = useState('')
    const [content, setContent] = useState('')

    return (
        <>
            <Header />
            <BannerTop />
            <DataSaran nama={nama} setNama={setNama} nik={nik} setNik={setNik} content={content} setContent={setContent} />
            <BannerBottom />
            <Media />
            <Copyright />           
        </>
    )
}

export default Kritik
