import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import {GlobalState} from '../../../context/GlobalContext'

const DataTambahWarga = () => {

    const {baseUrl} = useContext(GlobalState)

    const [nama, setNama] = useState('')    
    const [nik, setNik] = useState('')
    const [noKk, setNoKk] = useState('')
    const [jenisKelamin, setJenisKelamin] = useState('')
    const [tempatLahir, setTempatLahir] = useState('')
    const [tglLahir, setTglLahir] = useState('')
    const [agama, setAgama] = useState('')
    const [pendidikan, setPendidikan] = useState('')
    const [pekerjaan, setPekerjaan] = useState('')
    const [statusPerkawinan, setStatusPerkawinan] = useState('')
    const [kewarganegaraan, setKewarganegaraan] = useState('')
    const [alamat, setAlamat] = useState('')
    const [rt, setRt] = useState('')
    const [rw, setRw] = useState('')
    const [kelurahan, setKelurahan] = useState('')
    const [kecamatan, setKecamatan] = useState('')
    const [kabupaten, setKabupaten] = useState('')
    const [propinsi, setPropinsi] = useState('')
    const [kodePos, setKodePos] = useState('')

    var history = useHistory()

    const tambahWarga = () => {
        var FormData = require('form-data');
        var data = new FormData();
        data.append('nama', nama);
        data.append('nik', nik);
        data.append('noKk', noKk);
        data.append('jenisKelamin', jenisKelamin);
        data.append('tempatLahir', tempatLahir);
        data.append('tglLahir', tglLahir);
        data.append('agama', agama);
        data.append('pendidikan', pendidikan);
        data.append('pekerjaan', pekerjaan);
        data.append('statusPerkawinan', statusPerkawinan);
        data.append('kewarganegaraan', kewarganegaraan);
        data.append('alamat', alamat);
        data.append('rt', rt);
        data.append('rw', rw);
        data.append('kelurahan', kelurahan);
        data.append('kecamatan', kecamatan);
        data.append('kabupaten', kabupaten);
        data.append('propinsi', propinsi);
        data.append('kodePos', kodePos);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/penduduk/tambahPenduduk.php`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };
                
        axios(config)
            .then(response => {
                console.log(response.data);
                history.push('/data-penduduk')
                window.scrollTo(0, 0)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="tambah-warga-container">
            <h3>Halaman Tambah Data Warga</h3>
            <div className="deskripsi-tambah-warga">
                <span>Silahkan Isi formulir data warga berikut untuk menambahkan data warga ke dalam sistem</span>
            </div>
            <div className="data-warga">
                <form>
                    <div className="item-data">
                        <span>Nama :</span>
                        <input type="text" name="nama" onChange={(e) => setNama(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>NIK :</span>
                        <input type="text" name="nik" onChange={(e) => setNik(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>No. Kartu Keluarga :</span>
                        <input type="text" name="no_kk" onChange={(e) => setNoKk(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>Jenis Kelamin :</span>
                        <input type="text" name="jenis_kelamin" onChange={(e) => setJenisKelamin(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>Tempat Lahir :</span>
                        <input type="text" name="tempat_lahir" onChange={(e) => setTempatLahir(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>Tanggal Lahir :</span>
                        <input type="text" name="tgl_lahir" onChange={(e) => setTglLahir(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>Agama :</span>
                        <input type="text" name="agama" onChange={(e) => setAgama(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>Pendidikan :</span>
                        <input type="text" name="pendidikan" onChange={(e) => setPendidikan(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>Pekerjaan :</span>
                        <input type="text" name="pekerjaan" onChange={(e) => setPekerjaan(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>Status Perkawinan :</span>
                        <input type="text" name="status_perkawinan" onChange={(e) => setStatusPerkawinan(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>Kewarganegaraan :</span>
                        <input type="text" name="kewarganegaraan" onChange={(e) => setKewarganegaraan(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>Alamat :</span>
                        <input type="text" name="alamat" onChange={(e) => setAlamat(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>RT :</span>
                        <input type="text" name="rt" onChange={(e) => setRt(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>RW :</span>
                        <input type="text" name="rw" onChange={(e) => setRw(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>Kelurahan :</span>
                        <input type="text" name="kelurahan" onChange={(e) => setKelurahan(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>Kecamatan :</span>
                        <input type="text" name="kecamatan" onChange={(e) => setKecamatan(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>Kabupaten :</span>
                        <input type="text" name="kabupaten" onChange={(e) => setKabupaten(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>Propinsi :</span>
                        <input type="text" name="propinsi" onChange={(e) => setPropinsi(e.target.value)} />
                    </div>
                    <div className="item-data">
                        <span>Kode Pos :</span>
                        <input type="text" name="kode_pos" onChange={(e) => setKodePos(e.target.value)} />
                    </div>
                    <div className="update-data-warga-btn" onClick={tambahWarga}>
                        <span >Tambah</span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DataTambahWarga
