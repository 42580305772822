import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { useHistory } from "react-router-dom"
import './Login.css'
import { GlobalState } from '../../context/GlobalContext'

const Login = ({ closePopUpView }) => {

    const { baseUrl, setAuthenticated } = useContext(GlobalState)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')

    const history = useHistory()

    // username dan password
    const [adminUser, setAdminUser] = useState('')
    const [adminPassword, setAdminPassword] = useState('')

    const handleLogin = () => {
        if (username === adminUser && password === adminPassword) {
            setAuthenticated(true)
            setErrorMessage('')
            closePopUpView()
            history.push('/dashboard-admin')
        } else {
            setAuthenticated(false)
            setErrorMessage('Username dan Password Tidak Sesuai')
        }
    }

    //url api
    const apiAuth = `${baseUrl}/api/auth/auth.php`

    //get all data api
    const getApi = async () => {
        await axios.get(apiAuth)
            .then(res => {
                setAdminUser(res.data[0].username)
                setAdminPassword(res.data[0].password)
            })
    }

    useEffect(() => {
        getApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <div className="login-container">
                <h3>Admin Login</h3>
                {errorMessage === '' ? null : (
                    <span className="error-message">{errorMessage}</span>
                )}
                <div className="login-subtitle">Nama Pengguna :</div>
                <div className="form-group">
                    <i className="fa fa-user" aria-hidden="true"></i>
                    <input type="text" placeholder="Masukkan Nama Pengguna..." onChange={(e) => setUsername(e.target.value)} />
                </div>
                <div className="login-subtitle">Kata Sandi :</div>
                <div className="form-group">
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    <input type="password" placeholder="Masukkan Kata Sandi..." onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="btn-login" onClick={handleLogin}>
                    Login
                </div>
                <div className="close-btn" onClick={closePopUpView} ><i className="fa fa-times" aria-hidden="true"></i></div>
            </div>
            <div className="overlay"></div>
        </>
    )
}

export default Login
