import React, {useContext, useEffect} from 'react'
import Copyright from '../../components/copyright/Copyright'
import Media from '../../components/media/Media'
import BannerTop from '../../components/afiliasi/BannerTop'
import BannerBottom from '../../components/afiliasi/BannerBottom'
import HeaderAdmin from '../../components/header/HeaderAdmin'
import './Admin.css'
import Sidebar from '../../components/sidebar/Sidebar'
import Overlay from '../../components/overlay/Overlay'
import { GlobalState } from '../../context/GlobalContext'
import DataAdminBerita from '../../components/data-admin/admin-berita/DataAdminBerita'

const AdminBerita = () => {
    const {sidebar } = useContext(GlobalState)

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <>
            <HeaderAdmin />
            {sidebar && <Sidebar />}
            <BannerTop />
            <DataAdminBerita />
            <BannerBottom />
            {sidebar && <Overlay />}
            <Media />
            <Copyright />
        </>
    )
}

export default AdminBerita
