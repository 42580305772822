import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import {GlobalState} from '../../../context/GlobalContext'

const DataAdminMonografi = () => {

    const {baseUrl} = useContext(GlobalState)

    const [monografi, setMonografi] = useState([])
    const [jumlah, setJumlah] = useState(null)
    const [lastUpdate, setLastUpdate] = useState(null)

    const apiMonografi = `${baseUrl}/api/monografi/monografi.php`

    const insertLastUpdate = (id) => {
        const d = new Date()
        const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
        const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jum'at", "Sabtu"]

        const day = days[d.getDay()]
        const date = d.getDate()
        const month = months[d.getMonth()]
        const year = d.getFullYear()

        setLastUpdate(`${day}, ${date} ${month} ${year}`)

        if(lastUpdate!==null){
            var FormData = require('form-data');
            var dataBaru = new FormData();
            dataBaru.append('tanggal', lastUpdate);

            var config = {
                method: 'post',
                url: `${baseUrl}/api/ubahLastUpdate.php?id=0`,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: dataBaru
            };

            axios(config)
                .then(function (response) {
                    console.log(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    const getApiMonografi = async () => {
        await axios.get(apiMonografi)
            .then(response => {
                setMonografi(response.data)
            })
    }

    const ubahMonografi = (id) => {
        insertLastUpdate()
        if (jumlah !== null) {
            var FormData = require('form-data');
            var dataBaru = new FormData();
            dataBaru.append('jumlah', jumlah);

            var config = {
                method: 'post',
                url: `${baseUrl}/api/monografi/ubahMonografi.php?id=${id}`,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: dataBaru
            };

            axios(config)
                .then(function (response) {
                    console.log(response.data);
                    getApiMonografi()
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    useEffect(() => {
        getApiMonografi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <>
        <span className="deskripsi-title">Data Monografi</span>
        <div className="deskripsi">
            <span className="deskripsi-subtitle">Data Monografi</span>
            <div className="monografi-container">
                {monografi.map(item => {
                    return (
                        <div className="form-monografi" key={item.id}>
                            <div className="info-monografi">
                                <div className="monografi-title">{item.jenis}</div>
                                <input type="text" nama="jumlah" defaultValue={`${item.jumlah}`||''} onChange={(e) => setJumlah(e.target.value)} />
                                <div className="btn-update" onClick={() => ubahMonografi(item.id)}>Update</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
        </>
    )
}

export default DataAdminMonografi
