import React from 'react'
import logoDesaku from '../../assets/icon/logo-desaku.png'
import logoKota from '../../assets/icon/logo-kota.png'
import kemendesa from '../../assets/icon/kemendesa.png'
import cv3energi from '../../assets/icon/3energi.png'
import './Copyright.css'

const Copyright = () => {

    const time = new Date()
    const year = time.getFullYear()

    return (
        <div className="copyright-container">
            <div className="copyright-text">
                <span>Copyright © 2020-{year} Aplikasi Desaku.App Versi 1.0<br />All Right Reserved</span>
            </div>
            <div className="supported">
                Supported By:
            </div>
            <div className="support">
                <img src={logoDesaku} alt="desaku.app" />
                <img src={logoKota} alt="logo kota" />
                <img src={kemendesa} alt="kemendesa" />
                <img src={cv3energi} alt="3energi" />
            </div>
        </div>
    )
}

export default Copyright
