import React, {useContext} from 'react'
import axios from 'axios'
import {GlobalState} from '../../../context/GlobalContext'

const ListBerita = ({ item, getDataBerita }) => {

    // const handleInfoDetailed = () => {
    //     infoDetailed(item)
    // }

    const {baseUrl} = useContext(GlobalState)
    
    const deleteWarga = () => {

        var config = {
            method: 'delete',
            url: `${baseUrl}/api/berita/hapusBerita.php?id=${item.id}`,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        axios(config)
            .then(response => {
                console.log(response.data.msg);
                getDataBerita()
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="list-berita-container">
            <div className="list-title">{item.title}</div>
            <div className="list-desc"><span className="author-list">{item.author} | </span><span className="date-list">{item.date}</span></div>
            <div className="image-list"><img src={`${item.imgUrl}`} alt={item.title}/></div>
            <div className="hapus-berita" onClick={deleteWarga}>
                <i id="remove-list-berita" className="fa fa-trash" aria-hidden="true" title="Hapus Data" ></i> Hapus
            </div>
        </div>
    )
}

export default ListBerita
