import React from 'react'
import './Overlay.css'

const Overlay = () => {
    return (
        <div className="overlay-container"></div>
    )
}

export default Overlay
