import React, { Component } from 'react'
import kopSurat from '../../../assets/icon/logo-surat.png'
// import ttdKades from '../../../assets/icon/ttd-kades.png'
import QRCode from 'react-qr-code'

export class FileSkduCetak extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tanggal: '',
            date: '',
            bulan: '',
            tahun: '',
            number: '',
            jenisSurat: 'Surat Keterangan Domisili Usaha'
          };
    }

    tanggalSurat = () => {
        let time = new Date()
        let year = time.getFullYear()
        let monthIdx = time.getMonth()
        let date = time.getDate()
        let monthNames = ["Januari", "Februari", "Maret", "April", "Mei", "Juni",
            "Juli", "Agustus", "September", "Oktober", "Nopember", "Desember"
        ]
        let month = monthNames[monthIdx]
        let x = Math.random() * (100 - 0) + 0;
        this.setState(
            {
                tanggal: `${date} ${month} ${year}`,
                date: `${("0"+date).slice(-2)}`,
                bulan: `${("0"+(monthIdx+1)).slice(-2)}`,
                tahun: `${year}`,
                number: `${("00"+x).slice(-3)}`
            }
        )
        this.props.ubahArsip([
            {
                jenis: this.state.jenisSurat,
                nama: this.props.identitas.nama,
                nomor: `${this.props.infoSurat!==null?this.props.infoSurat[0].kodeSurat:''}/${("00"+x).slice(-3)}/${this.props.infoSurat!==null?this.props.infoSurat[0].kodeWilayah:''}/${("0"+date).slice(-2)}.${("0"+(monthIdx+1)).slice(-2)}/${year}`
            }
        ])
    }

    componentDidMount() {
        this.tanggalSurat()
    }

    render() {
        return (
            <>
                <div className="form-surat-container">
                    <div className="header-surat">
                        <div className="logo-surat">
                            <img src={kopSurat} alt="logo kota" />
                        </div>
                        <div className="kop-surat">
                            <span>PEMERINTAH KABUPATEN {(this.props.pemdes.kabupaten).toUpperCase()}</span>
                            <span>KECAMATAN {(this.props.pemdes.kecamatan).toUpperCase()}</span>
                            <span>DESA {(this.props.pemdes.desa).toUpperCase()}</span>
                            <span>Desa {this.props.pemdes.desa} Kecamatan {this.props.pemdes.kecamatan} Kabupaten {this.props.pemdes.kabupaten} {this.props.pemdes.kodePos}</span>
                            <span>Website : {this.props.pemdes.website}</span>
                        </div>
                    </div>
                    <div>
                        <hr className="garis-1" />
                        <hr className="garis-2" />
                    </div>
                    <div className="judul-nomor-surat">
                    <span>{(this.state.jenisSurat).toUpperCase()}</span>
                    <span>Nomor : {this.props.infoSurat!==null && this.props.infoSurat[0].kodeSurat}/{this.state.number}/{this.props.infoSurat!==null && this.props.infoSurat[0].kodeWilayah}/{this.state.date}.{this.state.bulan}/{this.state.tahun}</span>
                    </div>
                    <div className="isi-surat">
                    <span>Yang bertanda tangan dibawah ini, Kepala Desa {this.props.pemdes.desa} Kecamatan {this.props.pemdes.kecamatan} Kabupaten {this.props.pemdes.kabupaten}, menerangkan dengan sebenarnya bahwa :</span>
                        <div className="biodata-surat">
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Nama</td>
                                        <td>:</td>
                                        <td>{this.props.identitas.nama}</td>
                                    </tr>
                                    <tr>
                                        <td>Tempat, Tanggal Lahir</td>
                                        <td>:</td>
                                        <td>{this.props.identitas.tempat}, {this.props.identitas.tanggalLahir}</td>
                                    </tr>
                                    <tr>
                                        <td>Jenis Kelamin</td>
                                        <td>:</td>
                                        <td>{this.props.identitas.jenisKelamin}</td>
                                    </tr>
                                    <tr>
                                        <td>Kewarganegaraan</td>
                                        <td>:</td>
                                        <td>Indonesia</td>
                                    </tr>
                                    <tr>
                                        <td>Agama</td>
                                        <td>:</td>
                                        <td>{this.props.identitas.agama}</td>
                                    </tr>
                                    <tr>
                                        <td>Status Perkawinan</td>
                                        <td>:</td>
                                        <td>{this.props.identitas.statusPerkawinan}</td>
                                    </tr>
                                    <tr>
                                        <td>NIK</td>
                                        <td>:</td>
                                        <td>{this.props.identitas.nik}</td>
                                    </tr>
                                    <tr>
                                        <td>Alamat</td>
                                        <td>:</td>
                                        <td>{this.props.identitas.alamat}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <span>{this.props.infoSurat!==null ? this.props.infoSurat[0].keteranganDomisiliUsaha:''}</span>
                        <span>Orang tersebut benar-benar mempunyai usaha <b>{this.props.jenisUsaha === '' ? ' - ' : this.props.jenisUsaha}</b> dengan nama perusahaan <b>{this.props.perusahaan === '' ? ' - ' : this.props.perusahaan}</b> yang berdomisili di <b>{this.props.alamatUsaha === '' ? ' - ' : this.props.alamatUsaha}</b>.</span>
                        <span>Demikian Surat Keterangan ini kami buat dengan sebenarnya dan untuk dipergunakan sebagaimana mestinya.</span>
                    </div>
                    <div className="signature">
                        <QRCode value={`Nama: ${this.props.identitas.nama} NIK: ${this.props.identitas.nik}`} size={80} bgColor={'#FFFFFF'} fgColor={'#000000'} />
                    </div>
                    <div className="ttd">
                        <span>{this.props.pemdes.desa}, {this.state.tanggal}</span>
                        <span>Kepala Desa {this.props.pemdes.desa},</span>
                        {/* <img src={ttdKades} alt="" /> */}
                        <span><u><b>{this.props.perangkat[0].nama}</b></u></span>
                    </div>
                </div>
            </>
        )
    }
}

export default FileSkduCetak