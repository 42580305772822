import React, { useState, useEffect, useContext } from 'react'
import KantorDesa from '../peta/KantorDesa'
import axios from 'axios'
import { GlobalState } from '../../context/GlobalContext'
import photoDefault from '../../assets/icon/favicon.png'
import './Profil.css'

const Profil = () => {

    const { pemdes, baseUrl } = useContext(GlobalState)

    const [perangkat, setPerangkat] = useState([])
    const [wilayah, setWilayah] = useState([])
    const [profil, setProfil] = useState([])

    const apiPerangkat = `${baseUrl}/api/perangkat/perangkat.php`
    const apiWilayah = `${baseUrl}/api/wilayah/wilayah.php`
    const apiProfil = `${baseUrl}/api/profil/profil.php`

    const getApi = async () => {
        const getApiPerangkat = axios.get(apiPerangkat)
        const getApiWilayah = axios.get(apiWilayah)
        const getApiProfil = axios.get(apiProfil)

        await axios.all([getApiPerangkat, getApiWilayah, getApiProfil]).then(
            axios.spread((...allData) => {
                const allPerangkatData = allData[0].data
                const allWilayahData = allData[1].data
                const allProfil = allData[2].data

                setPerangkat(allPerangkatData)
                setWilayah(allWilayahData)
                setProfil(allProfil)
            })
        )
    }

    useEffect(() => {
        getApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="profil-deskripsi">
            <h3>Profil Desa {pemdes.desa}</h3>
            <div className="deskripsi">
                <span className="sub-header">Profil Umum</span>
                <div className="detail-perangkat">
                    <table className="tabel-perangkat">
                        <tbody>
                            <tr>
                                <td>Nama Desa</td>
                                <td>:</td>
                                <td>{pemdes.desa}</td>
                            </tr>
                            <tr>
                                <td>Alamat Kantor</td>
                                <td>:</td>
                                <td>{pemdes.alamat}</td>
                            </tr>
                            <tr>
                                <td>Kecamatan</td>
                                <td>:</td>
                                <td>{pemdes.kecamatan}</td>
                            </tr>
                            <tr>
                                <td>Kabupaten/Kota</td>
                                <td>:</td>
                                <td>{pemdes.kabupaten}</td>
                            </tr>
                            <tr>
                                <td>Propinsi</td>
                                <td>:</td>
                                <td>{pemdes.propinsi}</td>
                            </tr>
                            <tr>
                                <td>Kode Pos</td>
                                <td>:</td>
                                <td>{pemdes.kodePos}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="deskripsi">
                <span className="sub-header">Sejarah Desa</span>
                <span className="content-profile">{profil[0]?.sejarah}</span>
                <span className="content-profile">
                    Desa Mekarjaya merupakan satu dari {wilayah.length} Wilayah Administratif yang berada di Wilayah Kecamatan Cidolog Kabupaten Sukabumi. Desa Mekarjaya mempunyai luas wilayah seluas 1.157.507 Ha. Dengan batas desa sebagai berikut :
                </span>
                <div className="detail-perangkat">
                    <table className="tabel-perangkat">
                        <tbody>
                            <tr>
                                <td>Sebelah Utara</td>
                                <td>:</td>
                                <td>{profil[0]?.utara}</td>
                            </tr>
                            <tr>
                                <td>Sebelah Selatan</td>
                                <td>:</td>
                                <td>{profil[0]?.selatan}</td>
                            </tr>
                            <tr>
                                <td>Sebelah Barat</td>
                                <td>:</td>
                                <td>{profil[0]?.barat}</td>
                            </tr>
                            <tr>
                                <td>Sebelah Timur</td>
                                <td>:</td>
                                <td>{profil[0]?.timur}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <KantorDesa />
            <div className="deskripsi">
                <span className="sub-header">Sosial Masyarakat</span>
                <span className="content-profile">{profil[0]?.sosial}</span>
            </div>
            <div className="deskripsi">
                <span className="sub-header">Sosial Ekonomi</span>
                <span className="content-profile">{profil[0]?.ekonomi}</span>
            </div>
            <div className="deskripsi">
                <span className="sub-header">Wilayah Administratif</span>
                <span className="content-profile">Desa {pemdes.desa} terdiri dari {wilayah.length} Wilayah Administratif, yaitu:</span>
                {wilayah.map((item, idx) => {
                    return (
                        <span key={item.id}>{idx + 1}. Dusun {item.dusun}</span>
                    )
                })}
            </div>
            <div className="deskripsi">
                <span className="sub-header">Profil Kepala Desa {pemdes.desa}:</span>
                {
                    profil[0]?.imgKades.split('/')[5] !== 'undefined' && (
                        <div style={{
                            width: '100%'
                        }}>
                            <img style={{
                                width: 180,
                                display: 'block',
                                marginTop: 10,
                                marginBottom: 10
                            }} src={profil[0]?.imgKades} alt="kepala-desa" />
                        </div>
                    )
                }
                <div className="detail-perangkat">
                    <table className="tabel-perangkat">
                        <tbody>
                            <tr>
                                <td>Nama</td>
                                <td>:</td>
                                <td>{profil[0]?.namaKades}</td>
                            </tr>
                            <tr>
                                <td>Tempat, Tanggal Lahir</td>
                                <td>:</td>
                                <td>{profil[0]?.tempatLahir}, {profil[0]?.tanggalLahir}</td>
                            </tr>
                            <tr>
                                <td>TMT Pengangkatan</td>
                                <td>:</td>
                                <td>{profil[0]?.tanggalPengangkatan}</td>
                            </tr>
                            <tr>
                                <td>Alamat</td>
                                <td>:</td>
                                <td>{profil[0]?.alamat}</td>
                            </tr>
                            <tr>
                                <td>RT/RW</td>
                                <td>:</td>
                                <td>{profil[0]?.rt}/{profil[0]?.rw}</td>
                            </tr>
                            <tr>
                                <td>Desa</td>
                                <td>:</td>
                                <td>{profil[0]?.desa}</td>
                            </tr>
                            <tr>
                                <td>Kecamatan</td>
                                <td>:</td>
                                <td>{profil[0]?.kecamatan}</td>
                            </tr>
                            <tr>
                                <td>Kabupaten</td>
                                <td>:</td>
                                <td>{profil[0]?.kabupaten}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="deskripsi">
                <span className="sub-header">Perangkat Desa {pemdes.desa}</span>
                <span>Berikut merupakan nama perangkat desa Pemerintah Desa {pemdes.desa} Periode {pemdes.periode}:</span>
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Nama</th>
                            <th>Foto</th>
                            <th>Jabatan</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            perangkat.map((user, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{idx + 1}</td>
                                        <td>{user.nama}</td>
                                        <td>{user.imgUrl.split('/')[5] !== '' ? (
                                            <div className="foto-perangkat" >
                                                <img src={user.imgUrl} alt="perangkat" />
                                            </div>
                                        ) : (
                                            <div className="foto-perangkat" >
                                                <img src={photoDefault} alt="perangkat" />
                                            </div>
                                        )}</td>
                                        <td>{user.jabatan}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="deskripsi">
                <span className="sub-header">Jam Operasional</span>
                <div className="operasional">
                    <span><i className={profil[0]?.senin.toLowerCase().includes('libur')?"fa fa-times-circle":"fa fa-check-circle"} aria-hidden="true"></i>Senin : {profil[0]?.senin}</span>
                    <span><i className={profil[0]?.selasa.toLowerCase().includes('libur')?"fa fa-times-circle":"fa fa-check-circle"} aria-hidden="true"></i>Selasa : {profil[0]?.selasa}</span>
                    <span><i className={profil[0]?.rabu.toLowerCase().includes('libur')?"fa fa-times-circle":"fa fa-check-circle"} aria-hidden="true"></i>Rabu : {profil[0]?.rabu}</span>
                    <span><i className={profil[0]?.kamis.toLowerCase().includes('libur')?"fa fa-times-circle":"fa fa-check-circle"} aria-hidden="true"></i>Kamis : {profil[0]?.kamis}</span>
                    <span><i className={profil[0]?.jumat.toLowerCase().includes('libur')?"fa fa-times-circle":"fa fa-check-circle"} aria-hidden="true"></i>Jum'at : {profil[0]?.jumat}</span>
                    <span><i className={profil[0]?.sabtu.toLowerCase().includes('libur')?"fa fa-times-circle":"fa fa-check-circle"} aria-hidden="true"></i>Sabtu : {profil[0]?.sabtu}</span>
                    <span><i className={profil[0]?.minggu.toLowerCase().includes('libur')?"fa fa-times-circle":"fa fa-check-circle"} aria-hidden="true"></i>Minggu : {profil[0]?.minggu}</span>
                </div>
            </div>
        </div>
    )
}

export default Profil
