import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { GlobalState } from '../../context/GlobalContext'
import userIcon from '../../assets/icon/user.png'
import backIcon from '../../assets/icon/left-arrow.png'
import menuBarIcon from '../../assets/icon/menu-bar.png'
import './Header.css'

const HeaderAdmin = () => {

    const { setSidebar } = useContext(GlobalState)
    const [waktu, setWaktu] = useState("")

    const greeting = () => {
        let time = new Date()
        let hours = time.getHours()

        if (hours >= 4 && hours < 10) {
            setWaktu("Selamat Pagi")
        } else if (hours >= 10 && hours < 14) {
            setWaktu("Selamat Siang")
        } else if (hours >= 14 && hours < 19) {
            setWaktu("Selamat Sore")
        } else {
            setWaktu("Selamat Malam")
        }
    }

    let url = window.location
    let path = url.pathname.split('/')
    let page = path.pop().toUpperCase().replaceAll('-', ' ')

    let history = useHistory()

    const handleBackPage = () => {
        history.goBack()
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        greeting()
    }, [waktu])

    return (
        <div className="header-container">
            {(
                page === "INFO DATA PENDUDUK")
                || (page === "TAMBAH WARGA")
                || (page === "TAMBAH BERITA")
                || (page === "TAMBAH PRODUK")
                || (page === "UBAH PRODUK")
                || (page === "TAMBAH LEMBAGA")
                || (page === "UBAH LEMBAGA")
                || (page === "TAMBAH ASET")
                || (page === "UBAH ASET")
                || (page === "TAMBAH CAROUSEL")
                ? (
                    <div className="logo" onClick={handleBackPage}>
                        <img className="fa fa-chevron-left" src={backIcon} alt="back" />
                    </div>
                ) : (
                    <div className="menu-bar" onClick={() => setSidebar(true)}>
                        <img src={menuBarIcon} alt="logo desaku.net" />
                    </div>
                )}
            {(
                page === "INFO DATA PENDUDUK")
                || (page === "TAMBAH WARGA")
                || (page === "TAMBAH BERITA")
                || (page === "TAMBAH PRODUK")
                || (page === "UBAH PRODUK")
                || (page === "TAMBAH LEMBAGA")
                || (page === "UBAH LEMBAGA")
                || (page === "TAMBAH ASET")
                || (page === "UBAH ASET")
                || (page === "TAMBAH CAROUSEL")
                ? (
                    <>
                        <div className="page">
                            <span>{page}</span>
                        </div>
                        <div className="personalisasi">
                            <img className="fa fa-user" src={userIcon} alt="user" />
                        </div>
                    </>
                ) : (
                    <div className="personalisasi">
                        <span className="admin-hello">Selamat Datang, Admin</span>
                        <img className="fa fa-user" src={userIcon} alt="user" />
                    </div>
                )}
        </div>
    )
}

export default HeaderAdmin