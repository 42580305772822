import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { GlobalState } from '../../../context/GlobalContext'

const DataArsip = () => {

    const { baseUrl } = useContext(GlobalState)

    const [arsip, setArsip] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const [page, setPage] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const dataPerPage = 50
    const currentPage = page * dataPerPage

    const arsipUrl = `${baseUrl}/api/arsip/arsip.php`

    const getArsip = () => {
        setIsLoading(true)
        axios.get(arsipUrl)
            .then(result => {
                setArsip(result.data)
                setTotalPages(Math.ceil(result.data.length / dataPerPage))
                setIsLoading(false)
            })
    }

    // pagination
    const nextPage = () => {
        if (page < totalPages - 1) {
            setPage(prev => prev + 1)
        }
    }

    const prevPage = () => {
        if (page !== 0) {
            setPage(prev => prev - 1)
        }
    }

    const pagination = () => {
        return (
            <div className="pagination-container">
                {page !== 0 && (
                    <div className="arrow" onClick={prevPage}>
                        <i className="fa fa-arrow-left"></i>
                    </div>
                )}
                <div className="info-halaman">Halaman {page + 1} dari total {totalPages} Halaman</div>
                {page !== totalPages - 1 && (
                    <div className="arrow" onClick={nextPage}>
                        <i className="fa fa-arrow-right"></i>
                    </div>
                )}
            </div>
        )
    }

    useEffect(() => {
        getArsip()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="data-admin-deskripsi">
            <h3>Data Arsip Surat</h3>
            <span>Data arsip pengajuan surat elektronik adalah sebagai berikut :</span>
            <table className="arsip-table">
                <thead>
                    <tr>
                        <th className="col-nama">Nama</th>
                        <th className="col-nomor">Nomor</th>
                        <th className="col-jenis">Jenis</th>
                    </tr>
                </thead>
                {
                    isLoading ? (
                        <>
                            <tbody></tbody>
                        </>
                    ) : (
                        <tbody>
                            {
                                arsip !== null && (
                                    arsip.reverse().slice(currentPage, currentPage + dataPerPage).map((item) => {
                                        return (
                                            <tr key={item.id}>
                                                <td className="col-nama">{item.nama}</td>
                                                <td className="col-nomor">{item.nomor}</td>
                                                <td className="col-jenis">{item.jenis}</td>
                                            </tr>
                                        )
                                    })
                                )
                            }
                        </tbody>
                    )
                }
            </table>
            {
                isLoading ? (
                    <div className="loading-msg">Loading...</div>
                ) : (
                    pagination()
                )
            }
        </div>
    )
}

export default DataArsip
