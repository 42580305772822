import React, { useState, useEffect, useContext } from 'react'
import { GlobalState } from '../../context/GlobalContext'
import axios from 'axios'
import './DataAset.css'

const DataAset = () => {

    const { pemdes, baseUrl } = useContext(GlobalState)

    const [aset, setAset] = useState([])

    const apiAset = `${baseUrl}/api/aset/aset.php`

    const getApiAset = async () => {
        await axios.get(apiAset)
            .then(res => setAset(res.data))
    }

    useEffect(() => {
        getApiAset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="data-aset-container">
            <h3>Data Aset Desa {pemdes.desa}</h3>
            <span>Berikut merupakan beberapa aset yang dimiliki oleh Desa {pemdes.desa}:</span>
            <div className="deskripsi">
                {
                    aset.length > 0 && (
                        aset.map((res, index) => {
                            return (
                                <div className="detail" key={res.id}>
                                    <span className="aset-title">{index + 1}. {res.title}</span>
                                    <div className="aset-gambar">
                                        <img src={res.imgUrl} alt={`${res.title}`} />
                                    </div>
                                    <span className="desc">{res.desc}</span>
                                </div>
                            )
                        })
                    )
                }
            </div>
        </div>
    )
}

export default DataAset
