import React, { useContext, useEffect } from 'react'
import Copyright from '../../components/copyright/Copyright'
import Media from '../../components/media/Media'
import BannerTop from '../../components/afiliasi/BannerTop'
import BannerBottom from '../../components/afiliasi/BannerBottom'
import HeaderAdmin from '../../components/header/HeaderAdmin'
import './Admin.css'
import Sidebar from '../../components/sidebar/Sidebar'
import Overlay from '../../components/overlay/Overlay'
import { GlobalState } from '../../context/GlobalContext'
import DataAdminCarousel from '../../components/data-admin/carousel/DataAdminCarousel'

const AdminCarousel = () => {

    const { sidebar } = useContext(GlobalState)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <HeaderAdmin />
            {sidebar && <Sidebar />}
            <BannerTop />
            <DataAdminCarousel />
            <BannerBottom />
            {sidebar && <Overlay />}
            <Media />
            <Copyright />
        </>
    )
}

export default AdminCarousel