import React from 'react'
import './Video.css'

const Video = () => {
    return (
        <div className="video-container">
            <h3>Video Terbaru</h3>
            <div className="video">
                <iframe width="100%" height="250" src="https://www.youtube.com/embed/5sGyYmmXP54" title="Aplikasi Desaku.App" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        </div>
    )
}

export default Video
