import React, {useEffect} from 'react'
import Header from '../../../components/header/Header'
import Copyright from '../../../components/copyright/Copyright'
import Media from '../../../components/media/Media'
import BannerTop from '../../../components/afiliasi/BannerTop'
import DataDomisili from './DataDomisili'
import './Domisili.css'

const Domisili = () => {
    
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <>
            <Header />
            <BannerTop />
            <DataDomisili />
            <Media />
            <Copyright />
        </>
    )
}

export default Domisili
