import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { GlobalState } from '../../context/GlobalContext'
import './Infografis.css'

const DataInfografis = () => {

    const { pemdes, baseUrl } = useContext(GlobalState)

    const [monografi, setMonografi] = useState([])
    const [lastUpdate, setLastUpdate] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const apiMonografi = `${baseUrl}/api/monografi/monografi.php`
    const apiLastUpdate = `${baseUrl}/api/lastUpdate.php`

    const getApi = async () => {
        setIsLoading(true)

        const getApiMonografi = axios.get(apiMonografi)
        const getApiLastUpdate = axios.get(apiLastUpdate)

        await axios.all([getApiMonografi, getApiLastUpdate])
            .then(axios.spread((dataMonografi, dataLastUpdate) => {
                setMonografi(dataMonografi.data)

                setLastUpdate(dataLastUpdate.data[0].tanggal)
            }))
        setIsLoading(false)
    }

    useEffect(() => {
        getApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="infografis-container">
            <h3>Monografi Desa {pemdes.desa}</h3>
            <span>Berikut beberapa data mengenai Desa {pemdes.desa} yang disajikan dalam angka:</span>
            <div className="info">
                {
                    isLoading ? <span className="loading-text"><i className="fa fa-spinner" aria-hidden="true"></i> Loading...</span> :
                        (monografi.map(item => {
                            return (
                                <div className="info-data" key={item.id}>
                                    <span className="item-info">{item.jenis}</span>
                                    <div className="info-desc">
                                        <span>{item.jumlah}</span>
                                        <i className={`fa ${item.icon}`} aria-hidden="true"></i>
                                    </div>
                                </div>
                            )
                        }))
                }
            </div>
            <span className="update">Terakhir Update : <strong>{lastUpdate}</strong></span>
        </div>
    )
}

export default DataInfografis