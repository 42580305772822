import React from 'react'
import './DataSaran.css'

const DataSaran = ({ nama, setNama, nik, setNik, content, setContent }) => {

    const phone = 6282244880818

    return (
        <div className="saran-container">
            <h3>Kritik dan Saran</h3>
            <span>Jika ada masukan kritik atau saran untuk perbaikan pelayanan. Silahkan kirimkan kritik dan saran anda dengan mengisi form di bawah ini!</span>
            <form>
                <div className="form-saran">
                    <label htmlFor="nama">Nama :</label>
                    <input type="text" name="nama" placeholder="Isikan Nama Anda" onChange={(e) => setNama(e.target.value)} />
                </div>
                <div className="form-saran">
                    <label htmlFor="nik">NIK :</label>
                    <input type="text" name="nik" placeholder="Isikan NIK Anda" onChange={(e) => setNik(e.target.value)} />
                </div>
                <div className="form-saran">
                    <label htmlFor="saran">Kritik dan Saran :</label>
                    <textarea type="text" name="saran" placeholder="Masukkan Kritik dan Saran Anda" onChange={(e) => setContent(e.target.value)} />
                </div>
                <a href={`https://wa.me/${phone}?text=Dari%20${nama},%20NIK%20${nik},%20Isi%20Saran:%20${content}`} target="_blank" rel="noopener noreferrer">
                    <div className="btn-small">KIRIM</div>
                </a>
            </form>
        </div>
    )
}

export default DataSaran
