import React, { useEffect, useState, useContext } from 'react'
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa'
import { GlobalState } from '../../context/GlobalContext'
import Link from '@mui/material/Link';
import './Media.css'
import axios from 'axios';

const Media = () => {
    const { baseUrl } = useContext(GlobalState)

    const [data, setData] = useState({
        facebook: "",
        twitter: "",
        instagram: "",
        youtube: ""
    })

    const {
        facebook, twitter, instagram, youtube
    } = data

    const apiSocialMedia = `${baseUrl}/api/media/media.php`

    // get latest data social media
    const getLatestSocialMediaData = async () => {
        await axios.get(apiSocialMedia)
            .then(res => {
                setData({
                    ...data,
                    facebook: res.data[0]?.facebook,
                    twitter: res.data[0]?.twitter,
                    instagram: res.data[0]?.instagram,
                    youtube: res.data[0]?.youtube,
                })
            })
    }

    useEffect(() => {
        getLatestSocialMediaData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="media-container">
            <h3>Ikuti Kami</h3>
            <div className="media-icon">
                <div className="icon">
                    <Link href={`${facebook}`} target="_blank"><FaFacebookF /></Link>
                </div>
                <div className="icon">
                    <Link href={`${twitter}`} target="_blank"><FaTwitter /></Link>
                </div>
                <div className="icon">
                    <Link href={`${instagram}`} target="_blank"><FaInstagram /></Link>
                </div>
                <div className="icon">
                    <Link href={`${youtube}`} target="_blank"><FaYoutube /></Link>
                </div>
            </div>
        </div>
    )
}

export default Media
