import React, { useState, useEffect, useContext } from 'react'
import iconDowload from '../../assets/icon/download-icon.png'
import axios from 'axios'
import { GlobalState } from '../../context/GlobalContext'
import './DataRkpDesa.css'

const DataRkpDesa = () => {

    const { pemdes, baseUrl } = useContext(GlobalState)

    const [file, setFile] = useState(null)

    const ApiRKPDesa = `${baseUrl}/api/rkpdesa/rkpdesa.php`

    const getApiRkpDesa = async () => {
        await axios.get(ApiRKPDesa)
            .then(response => {
                setFile(response.data)
            })
    }

    useEffect(() => {
        getApiRkpDesa()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="rkp-deskripsi">
            <h3>Rencana Kerja Pemerintah Desa {pemdes.desa} 2021</h3>
            <span>Rencana pembangunan satu tahun kedepan yang akan dilaksanakan di Desa merupakan rencana strategis untuk menciptakan rasa keadilan dalam pemerataan pembangunan sehingga masyarakat dapat menikmati hasil dari pembangunan itu sendiri.</span>
            <span>Penyusunan RKP Desa ini sebagai referensi untuk pembangunan di Desa sesuai Undang-Undang Nomor 6 Tahun 2014 tentang Desa, Permendes, PDTT Nomor 01 tahun 2015 tentang Pedoman Kewenangan Berdasarkan Hak Asal Usul dan kewenangan Lokal Berskala Desa dan dan Permendes, PDTT Nomor 17 tahun 2019 tentang Pedoman Umum Pembangunan Pemberdayaan Masyarakat Desa.</span>
            <span>Berikut merupakan dokumen RKPDesa {pemdes.desa} Tahun anggaran 2021. Dokumen tersebut dapat diunduh pada link di bawah ini:</span>
            <div className="rkp-image">
                {file !== null && (
                    <a href={`${baseUrl}/images/rkpdesa/${file[0].file}`} target="_blank" rel="noreferrer" download>
                        <img src={iconDowload} alt="RKPDesa" />
                    </a>
                )
                }
            </div>
        </div>
    )
}

export default DataRkpDesa