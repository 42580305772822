import HomeIcon from '../../assets/icon/home-icon.png'
import UsersIcon from '../../assets/icon/users-icon.png'
import MailIcon from '../../assets/icon/mail-icon.png'
import PostIcon from '../../assets/icon/post-icon.png'
import ProductIcon from '../../assets/icon/product-icon.png'

export const sidebarData = [
    {
        id: 1,
        name: 'Dashboard',
        path: '/dashboard-admin',
        icon: HomeIcon
    },
    {
        id: 2,
        name: 'Data Profil',
        path: '/ubah-profil',
        icon: UsersIcon
    },
    {
        id: 3,
        name: 'Data Penduduk',
        path: '/data-penduduk',
        icon: UsersIcon
    },
    {
        id: 4,
        name: 'Arsip Surat',
        path: '/arsip-surat',
        icon: MailIcon
    },
    {
        id: 5,
        name: 'Pengaturan Surat',
        path: '/pengaturan-surat',
        icon: MailIcon
    },
    {
        id: 6,
        name: 'Berita',
        path: '/admin-berita',
        icon: PostIcon
    },
    {
        id: 7,
        name: 'Lembaga',
        path: '/admin-lembaga',
        icon: PostIcon
    },
    {
        id: 8,
        name: 'Produk UMKM',
        path: '/admin-produk',
        icon: ProductIcon
    },
    {
        id: 9,
        name: 'Aset',
        path: '/admin-aset',
        icon: ProductIcon
    },
    {
        id: 10,
        name: 'Carousel',
        path: '/admin-carousel',
        icon: ProductIcon
    }
]