import React, { useState, useContext } from 'react'
import {GlobalState} from '../../../context/GlobalContext'
import { useHistory } from 'react-router-dom'
import axios from 'axios'

const DataTambahBerita = () => {

    const {baseUrl} = useContext(GlobalState)

    const [data, setData] = useState({
        title: "",
        author: "",
        date: "",
        desc: "",
        text1: "",
        text2: "",
        text3: ""
    })

    const { title, author, date, desc, text1, text2, text3 } = data

    const [file, setFile] = useState(null)

    var history = useHistory()

    const uploadImageBerita = () => {
        const FormData = require('form-data');
        const dataBaru = new FormData();
        dataBaru.append('anyfile', file);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/upload/uploadImageBerita.php`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: dataBaru
        };

        axios(config)
            .then(function (response) {
                console.log(response.data)
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    const tambahBerita = () => {

        uploadImageBerita()

        const FormData = require('form-data');
        const data = new FormData();
        data.append('title', title);
        data.append('author', author);
        data.append('date', date);
        data.append('imgUrl', `${baseUrl}/images/berita/`+file.name);
        data.append('desc', desc);
        data.append('text1', text1);
        data.append('text2', text2);
        data.append('text3', text3);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/berita/tambahBerita.php`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        axios(config)
            .then(response => {
                console.log(response.data);
                history.push('/admin-berita')
                window.scrollTo(0, 0)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="tambah-warga-container">
            <h3>Halaman Tambah Data Berita</h3>
            <div className="deskripsi-tambah-warga">
                <span>Silahkan Isi form berikut untuk menambahkan berita :</span>
            </div>
            <div className="data-warga">
                <form>
                    <div className="item-data">
                        <span>Judul :</span>
                        <input type="text" name="title" onChange={(e) => setData({ ...data, title: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Pengarang :</span>
                        <input type="text" name="author" onChange={(e) => setData({ ...data, author: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Tanggal :</span>
                        <input type="date" name="date" onChange={(e) => setData({ ...data, date: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Upload Gambar :</span>
                        <input type="file" name="imgUrl" onChange={(e) => setFile(e.target.files[0])} />
                        <span style={{ fontSize: 9, fontWeight: "bold", paddingLeft: 8 }}>format data (.jpg/.jpeg/.png)</span>
                    </div>
                    <div className="item-data">
                        <span>Deskripsi :</span>
                        <input type="text" name="desc" onChange={(e) => setData({ ...data, desc: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Paragraf Pertama :</span>
                        <textarea type="textarea" name="text1" onChange={(e) => setData({ ...data, text1: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Paragraf Kedua :</span>
                        <textarea name="text2" onChange={(e) => setData({ ...data, text2: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Paragraf Ketiga :</span>
                        <textarea type="textarea" name="text3" onChange={(e) => setData({ ...data, text3: e.target.value })} />
                    </div>
                    <div className="update-data-warga-btn" onClick={tambahBerita}>
                        <span >Tambah</span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DataTambahBerita
