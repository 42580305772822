import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {GlobalState} from '../../../context/GlobalContext'
import ListPenduduk from './ListPenduduk'

const DataAdminPenduduk = ({ infoDetailed }) => {

    const {baseUrl} = useContext(GlobalState)

    const [dataKependudukan, setDataKependudukan] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [page, setPage] = useState(0)
    const [totalPages, setTotalPages] = useState(0)
    const dataPerPage = 50
    const currentPage = page * dataPerPage

    const [filterData, setFilterData] = useState('')

    const pendudukUrl = `${baseUrl}/api/penduduk/penduduk.php`

    const getDataPenduduk = () => {
        setIsLoading(true)
        axios.get(pendudukUrl)
            .then(result => {
                setDataKependudukan(result.data)
                setTotalPages(Math.ceil(result.data.length / dataPerPage))
                setIsLoading(false)
            })
    }

    // pagination
    const nextPage = () => {
        if (page < totalPages - 1) {
            setPage(prev => prev + 1)
        }
    }

    const prevPage = () => {
        if (page !== 0) {
            setPage(prev => prev - 1)
        }
    }

    const pagination = () => {
        return (
            <div className="pagination-container">
                {page !== 0 && (
                    <div className="arrow" onClick={prevPage}>
                        <i className="fa fa-arrow-left"></i>
                    </div>
                )}
                <div className="info-halaman">Halaman {page + 1} dari total {totalPages} Halaman</div>
                {page !== totalPages - 1 && (
                    <div className="arrow" onClick={nextPage}>
                        <i className="fa fa-arrow-right"></i>
                    </div>
                )}
            </div>
        )
    }

    useEffect(() => {
        getDataPenduduk()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <div className="data-admin-deskripsi">
            <h3>Halaman Data Penduduk</h3>
            <span>Berikut merupakan halaman yang menampilkan data kependudukan warga. Silahkan Klick icon tambah untuk menambahkan data warga :</span>
            <div className="tool-bar">
                <div className="tambah-warga-icon">
                    <Link to="/tambah-warga">
                        Tambah
                    </Link>
                </div>
                <div className="filter-warga">
                    <i id="cari-warga" className="fa fa-search"></i>
                    <input type="text" name="cari" placeholder="Cari..." onChange={e => setFilterData(e.target.value)} />
                </div>
            </div>
            <table className="arsip-table">
                <thead>
                    <tr>
                        <th className="col-nama">Nama</th>
                        <th className="col-nik">NIK</th>
                        <th className="col-aksi">Aksi</th>
                    </tr>
                </thead>
                {
                    isLoading ? (
                        <>
                            <tbody></tbody>
                        </>
                    ) : (
                        <tbody>
                            {
                                dataKependudukan !== [] && (
                                    dataKependudukan.filter((warga) => {
                                        if (filterData === "") {
                                            return warga
                                        } else if ((warga.nama.toLowerCase().includes(filterData.toLocaleLowerCase()) || (warga.nik.includes(filterData)))) {
                                            return warga
                                        } else {
                                            return null
                                        }
                                    }).slice(currentPage, currentPage + dataPerPage).map((item) => {
                                        return (
                                            <ListPenduduk key={item.nik} item={item} infoDetailed={infoDetailed} getDataPenduduk={getDataPenduduk} />
                                        )
                                    })
                                )
                            }
                        </tbody>
                    )
                }
            </table>
            {
                isLoading ? (
                    <div className="loading-msg">Loading...</div>
                ) : (
                    pagination()
                )
            }
        </div>
    )
}

export default DataAdminPenduduk
