import React, { useEffect } from 'react'
import Copyright from '../../components/copyright/Copyright'
import Media from '../../components/media/Media'
import BannerTop from '../../components/afiliasi/BannerTop'
import BannerBottom from '../../components/afiliasi/BannerBottom'
import HeaderAdmin from '../../components/header/HeaderAdmin'
import './Admin.css'
import DataUbahLembaga from '../../components/data-admin/admin-lembaga/DataUbahLembaga'

const UbahLembaga = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <HeaderAdmin />
            <BannerTop />
            <DataUbahLembaga />
            <BannerBottom />
            <Media />
            <Copyright />
        </>
    )
}

export default UbahLembaga