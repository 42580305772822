import React from 'react'
import DataAdminProfil from './dashboard-admin/DataAdminProfil'
import DataAdminPerangkat from './dashboard-admin/DataAdminPerangkat'
import DataAdminMonografi from './dashboard-admin/DataAdminMonografi'
import DataAdminRKP from './dashboard-admin/DataAdminRKP'
import DataAdminAPBDesa from './dashboard-admin/DataAdminAPBDesa'
import DataAdminInformasi from './dashboard-admin/DataAdminInformasi'
import './DataAdmin.css'
import DataUbahAuthentifikasi from './dashboard-admin/DataUbahAuthentifikasi'
import DataUbahMediaSosial from './dashboard-admin/DataUbahMediaSosial'

const DataAdminHome = () => {
    return (
        <div className="data-admin-deskripsi">
            <h3>Dashboard Admin</h3>
            <DataAdminProfil />
            <DataAdminPerangkat />
            <DataAdminMonografi />
            <DataAdminRKP />
            <DataAdminAPBDesa />
            <DataAdminInformasi />
            <DataUbahAuthentifikasi />
            <DataUbahMediaSosial />
        </div>
    )
}

export default DataAdminHome
