import React from 'react'

const CartItem = ({ item }) => {

    return (
        <div className="list-produk-container">
            <div className="detail-produk-container">
                <div className="image-produk"><img src={`${item.imgUrl}`} alt={item.title} /></div>
                <div className="deskripsi-produk">
                    <div className="nama-produk">{item.name}</div>
                    <div className="rates">
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                    </div>
                    <div className="harga-produk"><i className="fa fa-tags" aria-hidden="true"></i>Harga : {`Rp. ${item.price} - Rp. ${item.price2 ? item.price2 : "-"}`}</div>
                    <div className="stok-produk"><i className="fa fa-shopping-cart" aria-hidden="true"></i>Stok : {item.stok}</div>
                    <div className="tambah-pesanan">
                        <a href={`https://wa.me/${item.phone}?text=Halo%20Gan%20Saya%20ingin%20membeli%20produk%20anda%20....`} target="_blank" rel="noopener noreferrer"><span><i className="fa fa-whatsapp" aria-hidden="true"></i>Hubungi Penjual</span></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CartItem
