import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import iconDownload from '../../assets/icon/download-icon.png'
import { GlobalState } from '../../context/GlobalContext'
import './DataApbdesa.css'

const DataApbdesa = () => {

    const { pemdes, baseUrl } = useContext(GlobalState)

    const [file, setFile] = useState(null)

    const ApiAPBDesa = `${baseUrl}/api/apbdesa/apbdesa.php`

    const getApiAPBDesa = async () => {
        await axios.get(ApiAPBDesa)
            .then(response => {
                setFile(response.data)
            })
    }

    useEffect(() => {
        getApiAPBDesa()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="data-apbdesa">
            <h3 className="apbdesa-title">Laporan APBDesa {pemdes.desa} 2021</h3>
            <span>Anggaran Pendapatan dan Belanja Desa (APBDes) adalah peraturan desa yang memuat sumber-sumber penerimaan dan alokasi pengeluaran desa dalam kurun waktu satu tahun. APB Desa terdiri atas bagian pendapatan desa, belanja desa, dan pembiayaan. Rancangan APBDes dibahas dalam musyawarah perencanaan pembangunan desa.</span>
            <span>Pendapatan desa merupakan penghasilan yang diperoleh desa yang bersumber dari pendapatan asli desa (PAD), pendapatan transfer ataupun pendapatan lain-lain desa. Pendapatan Transfer Desa Binangun berasal dari Dana Desa (DD), Alokasi Dana Desa (ADD), Bagi Hasil Pajak dan Retribusi (BHPR) dan Bantuan Keuangan Kabupaten (BKK). Dana desa sebagai salah satu sumber pendapatan desa, pengelolaannya dilakukan dalam kerangka pengelolaan Keuangan Desa. Keuangan desa dikelola berdasarkan asas-asas transparan, akuntabel, partisipatif, serta dilakukan dengan tertib dan disiplin anggaran.</span>
            <span>Belanja desa merupakan pengeluaran yang dilakukan oleh desa baik melalui rekening kas desa ataupun langsung dibayar ke supplier yang merupakan kewajiban dalam 1 tahun anggaran dan tidak diperoleh pembayaran kembali serta diprioritaskan untuk memenuhi kebutuhan desa yang disepakati dalam musyawarah yang meliputi 5 bidang, yakni (1) penyelenggaraan pemerintah desa, (2) pelaksanaan pembangunan desa, (3) pembinaan kemasyarakatan desa, (4) pemberdayaan masyarakat desa, dan (5) penanggulangan bencana, keadaan mendesak dan darurat desa.</span>
            <span>Data laporan APBDesa {pemdes.desa} dapat diunduh pada link di bawah ini.</span>
            <div className="apbdesa-image">
                {
                    file !== null && (
                        <a href={`${baseUrl}/images/apbdesa/${file[0].file}`} target="_blank" rel="noreferrer" download>
                            <img src={iconDownload} alt="APBDesa" />
                        </a>
                    )
                }
            </div>
        </div>
    )
}

export default DataApbdesa
