import React, {useEffect} from 'react'
import Header from '../../../components/header/Header'
import Copyright from '../../../components/copyright/Copyright'
import Media from '../../../components/media/Media'
import BannerTop from '../../../components/afiliasi/BannerTop'
import DataSkKehilangan from './DataSkKehilangan'

const SkKehilangan = () => {
    
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    return (
        <>
            <Header />
            <BannerTop />
            <DataSkKehilangan />
            <Media />
            <Copyright />
        </>
    )
}

export default SkKehilangan
