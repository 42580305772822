import React, { useState, useEffect, useContext } from 'react'
import { GlobalState } from '../../../context/GlobalContext'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ListLembaga from './ListLembaga'

const DataAdminBerita = () => {

    const { baseUrl } = useContext(GlobalState)

    const [dataLembaga, setDataLembaga] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const urlLembaga = `${baseUrl}/api/lembaga/lembaga.php`

    const getDataLembaga = () => {
        setIsLoading(true)
        axios.get(urlLembaga)
            .then(result => {
                setDataLembaga(result.data.reverse())
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getDataLembaga()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="data-admin-deskripsi">
            <h3>Halaman Lembaga</h3>
            <span>Berikut merupakan halaman yang menampilkan data lembaga. Silahkan Klik tambah untuk menambahkan lembaga :</span>
            <div className="menu-header">
                <div className="list-berita-subtitle">Daftar Lembaga</div>
                <div className="tambah-berita-icon">
                    <Link to="/tambah-lembaga">
                        Tambah
                    </Link>
                </div>
            </div>
            {
                dataLembaga !== [] && (
                    dataLembaga.map((item) => {
                        return (
                            <ListLembaga key={item.id} item={item} />
                        )
                    })
                )
            }
            {
                isLoading && <span style={{ fontSize: 9, textAlign: 'center' }}>Loading...</span>
            }
        </div>
    )
}

export default DataAdminBerita
