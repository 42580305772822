import React, { useEffect } from 'react'
import Header from '../../components/header/Header'
import Peta from '../../components/peta/Peta'
import Media from '../../components/media/Media'
import Copyright from '../../components/copyright/Copyright'
import Slider from '../../components/slider/Slider'
import Menu from '../../components/menu/Menu'
import Berita from '../../components/berita/Berita'
import Video from '../../components/video/Video'
import PapanInfo from '../../components/data-papan/PapanInfo'

const Dashboard = ({ berita, detailItemBerita }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Header />
            <Slider />
            <Menu />
            <Berita berita={berita} detailItemBerita={detailItemBerita} />
            <PapanInfo />
            <Video />
            <Peta />
            <Media />
            <Copyright />
        </>
    )
}

export default Dashboard
