import React, { useState, useEffect, useContext } from 'react'
import { GlobalState } from '../../../context/GlobalContext'
import axios from 'axios'

const DataUbahSurat = () => {

    const { baseUrl } = useContext(GlobalState)

    const [data, setData] = useState({
        kodeSurat: "",
        kodeWilayah: "",
        keteranganDomisili: "",
        keteranganSkck: "",
        keteranganSktm: "",
        keteranganPenghasilan: "",
        keteranganKehilangan: "",
        keteranganUsaha: "",
        keteranganDomisiliUsaha: "",
        keteranganPenduduk: "",
        keteranganUmum: "",
        pengantarUmum: ""
    })

    const {
        kodeSurat,
        kodeWilayah,
        keteranganDomisili,
        keteranganSkck,
        keteranganSktm,
        keteranganPenghasilan,
        keteranganKehilangan,
        keteranganUsaha,
        keteranganDomisiliUsaha,
        keteranganPenduduk,
        keteranganUmum,
        pengantarUmum
    } = data

    const apiSurat = `${baseUrl}/api/surat/surat.php`

    // get data pengaturan surat
    const getDataSurat = async () => {
        await axios.get(apiSurat)
            .then(res => {
                setData({
                    ...data,
                    kodeSurat: res.data[0]?.kodeSurat,
                    kodeWilayah: res.data[0]?.kodeWilayah,
                    keteranganDomisili: res.data[0]?.keteranganDomisili,
                    keteranganSkck: res.data[0]?.keteranganSkck,
                    keteranganSktm: res.data[0]?.keteranganSktm,
                    keteranganPenghasilan: res.data[0]?.keteranganPenghasilan,
                    keteranganKehilangan: res.data[0]?.keteranganKehilangan,
                    keteranganUsaha: res.data[0]?.keteranganUsaha,
                    keteranganDomisiliUsaha: res.data[0]?.keteranganDomisiliUsaha,
                    keteranganPenduduk: res.data[0]?.keteranganPenduduk,
                    keteranganUmum: res.data[0]?.keteranganUmum,
                    pengantarUmum: res.data[0]?.pengantarUmum
                })
            })
    }

    useEffect(() => {
        getDataSurat()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ubah surat
    const ubahSurat = () => {

        const FormData = require('form-data');
        const data = new FormData();
        data.append('kodeSurat', kodeSurat);
        data.append('kodeWilayah', kodeWilayah);
        data.append('keteranganDomisili', keteranganDomisili);
        data.append('keteranganSkck', keteranganSkck);
        data.append('keteranganSktm', keteranganSktm);
        data.append('keteranganPenghasilan', keteranganPenghasilan);
        data.append('keteranganKehilangan', keteranganKehilangan);
        data.append('keteranganUsaha', keteranganUsaha);
        data.append('keteranganDomisiliUsaha', keteranganDomisiliUsaha);
        data.append('keteranganPenduduk', keteranganPenduduk);
        data.append('keteranganUmum', keteranganUmum);
        data.append('pengantarUmum', pengantarUmum);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/surat/ubahSurat.php?id=1`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        axios(config)
            .then(response => {
                console.log(response.data);
                window.scrollTo(0, 0)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div className="tambah-warga-container">
            <h3>Halaman Ubah Data Surat</h3>
            <div className="deskripsi-tambah-warga">
                <span>Silahkan Isi form berikut untuk mengubah surat :</span>
            </div>
            <div className="data-warga">
                <form>
                    <div className="item-data">
                        <span>Kode Surat :</span>
                        <input type="text" name="kodeSurat" value={kodeSurat} onChange={(e) => setData({ ...data, kodeSurat: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Kode Wilayah :</span>
                        <input type="text" name="kodeWilayah" value={kodeWilayah} onChange={(e) => setData({ ...data, kodeWilayah: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Keterangan Domisili :</span>
                        <textarea type="textarea" name="keteranganDomisili" value={keteranganDomisili} onChange={(e) => setData({ ...data, keteranganDomisili: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Keterangan SKCK :</span>
                        <textarea type="textarea" name="keteranganSkck" value={keteranganSkck} onChange={(e) => setData({ ...data, keteranganSkck: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Keterangan Surat Keterangan Tidak Mampu :</span>
                        <textarea type="textarea" name="keteranganSktm" value={keteranganSktm} onChange={(e) => setData({ ...data, keteranganSktm: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Keterangan Penghasilan :</span>
                        <textarea type="textarea" name="keteranganPenghasilan" value={keteranganPenghasilan} onChange={(e) => setData({ ...data, keteranganPenghasilan: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Keterangan Kehilangan :</span>
                        <textarea type="textarea" name="keteranganKehilangan" value={keteranganKehilangan} onChange={(e) => setData({ ...data, keteranganKehilangan: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Keterangan Usaha :</span>
                        <textarea type="textarea" name="keteranganUsaha" value={keteranganUsaha} onChange={(e) => setData({ ...data, keteranganUsaha: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Keterangan Domisili Usaha :</span>
                        <textarea type="textarea" name="keteranganDomisiliUsaha" value={keteranganDomisiliUsaha} onChange={(e) => setData({ ...data, keteranganDomisiliUsaha: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Keterangan Penduduk :</span>
                        <textarea type="textarea" name="keteranganPenduduk" value={keteranganPenduduk} onChange={(e) => setData({ ...data, keteranganPenduduk: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Keterangan Umum :</span>
                        <textarea type="textarea" name="keteranganUmum" value={keteranganUmum} onChange={(e) => setData({ ...data, keteranganUmum: e.target.value })} />
                    </div>
                    <div className="item-data">
                        <span>Pengantar Umum :</span>
                        <textarea type="textarea" name="pengantarUmum" value={pengantarUmum} onChange={(e) => setData({ ...data, pengantarUmum: e.target.value })} />
                    </div>
                    <div className="update-data-warga-btn" onClick={ubahSurat}>
                        <span>Ubah</span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DataUbahSurat