import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import Header from '../../../components/header/Header'
import Copyright from '../../../components/copyright/Copyright'
import Media from '../../../components/media/Media'
import BannerTop from '../../../components/afiliasi/BannerTop'
import { FileSkckCetak } from './FileSkckCetak'
import axios from 'axios'
import { GlobalState } from '../../../context/GlobalContext'
import './CetakSkck.css'

const CetakSkck = ({ perangkat }) => {

    const { baseUrl, pemdes, identitas } = useContext(GlobalState)

    const [arsip, setArsip] = useState('')
    const [kode, setKode] = useState([])
    const [infoSurat, setInfoSurat] = useState(null)
    const [alert, setAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')

    let contain = {
        boxShadow: '0px 0px 5px gray'
    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Surat Pengantar SKCK",
    });

    const [keperluan, setKeperluan] = useState('')

    const simpan = () => {
        var nikFiltered = kode.filter(item => { return item === identitas.nik })
        if ((arsip[0].nama !== '') && (identitas.nik === nikFiltered[0])) {
            handlePrint()
            tambahDataArsip()
        } else {
            setAlertMessage('Maaf Data Anda Tidak Terdapat Pada Sistem Kami.\r\n Silahkan Hubungi Admin Untuk Info Lebih Lanjut!')
            setAlert(true)
        }
    }

    const closeAlert = () => {
        setAlert(false)
    }

    const ubahArsip = (data) => {
        setArsip(data)
    }

    const tambahDataArsip = () => {
        var FormData = require('form-data');
        var dataBaru = new FormData();
        dataBaru.append('nama', `${arsip[0].nama}`);
        dataBaru.append('nomor', `${arsip[0].nomor}`);
        dataBaru.append('jenis', `${arsip[0].jenis}`);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/arsip/tambahArsip.php`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: dataBaru
        };

        axios(config)
            .then(response => {
                console.log(response.data)
            })

    }

    //ambil data nik penduduk dan info surat
    const apiDataNik = `${baseUrl}/api/penduduk/penduduk.php`
    const apiInfoSurat = `${baseUrl}/api/surat/surat.php`

    //get all data api
    const getApi = async () => {
        const getDataNIK = axios.get(apiDataNik)
        const getInfoSurat = axios.get(apiInfoSurat)

        await axios.all([getDataNIK, getInfoSurat]).then(
            axios.spread((...allData) => {
                const allNikData = allData[0].data
                const allInfoSurat = allData[1].data

                setKode(allNikData.map(item => item.nik))
                setInfoSurat(allInfoSurat)
            })
        )
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getApi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Header />
            <BannerTop />
            <div className="option-surat">
                <h3>Tampilan Surat</h3>
                <div className="keperluan">
                    <label htmlFor="tujuan">Tujuan Pembuatan Surat</label>
                    <input type="text" onChange={(e) => setKeperluan(e.target.value)} />
                </div>
                <div className="tombol">
                    <div className="simpan" onClick={simpan}>Cetak</div>
                    <Link to='/surat-elektronik'>
                        <div className="kembali-surat">Kembali ke Surat</div>
                    </Link>
                </div>
            </div>
            <div className="container" style={contain}>
                <FileSkckCetak
                    ref={componentRef}
                    infoSurat={infoSurat}
                    ubahArsip={ubahArsip}
                    perangkat={perangkat}
                    pemdes={pemdes}
                    identitas={identitas}
                    keperluan={keperluan} />
            </div>
            {
                alert ? (
                    <>
                        <div className="alert-container">
                            <i className="fa fa-times-circle"></i>
                            <span>{alertMessage}</span>
                            <div className="ok-btn" onClick={closeAlert} >Oke</div>
                        </div>
                        <div className="overlay"></div>
                    </>
                ) : (null)
            }
            <Media />
            <Copyright />
        </>
    )
}

export default CetakSkck
