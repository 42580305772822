import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { GlobalState } from '../../../context/GlobalContext'

const DataDomisili = () => {

    const { identitas, setIdentitas } = useContext(GlobalState)

    return (
        <div className="data-domisili-container">
            <h3>Formulir Surat Keterangan Domisili</h3>
            <span>Silahkan isi data diri sesuai dengan data pada KTP Anda.</span>
            <div className="form-domisili">
                <form>
                    <div className="form-content">
                        <label htmlFor="nama">Nama Lengkap</label>
                        <input type="text" nama="nama" placeholder="Tuliskan Nama" onChange={(e) => setIdentitas({ ...identitas, nama: e.target.value })} />
                    </div>
                    <div className="form-content">
                        <label htmlFor="tempat">Tempat Lahir</label>
                        <input type="text" name="tempat" placeholder="Tuliskan Nama Kota Kelahiran" onChange={(e) => setIdentitas({ ...identitas, tempat: e.target.value })} />
                    </div>
                    <div className="form-content">
                        <label htmlFor="tanggal">Tanggal Lahir</label>
                        <input type="text" name="tanggal" placeholder="Tuliskan Tanggal Lahir. Contoh: 01-01-2001" onChange={(e) => setIdentitas({ ...identitas, tanggalLahir: e.target.value })} />
                    </div>
                    <div className="form-content">
                        <label htmlFor="jenis-kelamin">Jenis Kelamin</label>
                        <input type="text" nama="jenis-kelamin" placeholder="Tuliskan Jenis Kelamin" onChange={(e) => setIdentitas({ ...identitas, jenisKelamin: e.target.value })} />
                    </div>
                    <div className="form-content">
                        <label htmlFor="agama">Agama</label>
                        <input type="text" nama="agama" placeholder="Tuliskan Agama" onChange={(e) => setIdentitas({ ...identitas, agama: e.target.value })} />
                    </div>
                    <div className="form-content">
                        <label htmlFor="status">Status Perkawinan</label>
                        <input type="text" nama="status" placeholder="Tuliskan Status Perkawinan" onChange={(e) => setIdentitas({ ...identitas, statusPerkawinan: e.target.value })} />
                    </div>
                    <div className="form-content">
                        <label htmlFor="nik">NIK</label>
                        <input type="text" nama="nik" placeholder="Tuliskan NIK" onChange={(e) => setIdentitas({ ...identitas, nik: e.target.value })} />
                    </div>
                    <div className="form-content">
                        <label htmlFor="alamat">Alamat</label>
                        <input type="text" nama="alamat" placeholder="Tuliskan Alamat" onChange={(e) => setIdentitas({ ...identitas, alamat: e.target.value })} />
                    </div>
                    <br />
                    <Link to='/surat/keterangan-domisili/cetak-surat'>
                        <div className="btn-small">Buat</div>
                    </Link>
                </form>
            </div>
        </div>
    )
}

export default DataDomisili
