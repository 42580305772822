import React, { useEffect } from 'react'
import Copyright from '../../components/copyright/Copyright'
import Media from '../../components/media/Media'
import BannerTop from '../../components/afiliasi/BannerTop'
import BannerBottom from '../../components/afiliasi/BannerBottom'
import HeaderAdmin from '../../components/header/HeaderAdmin'
import DataUbahProduk from '../../components/data-admin/produk/DataUbahProduk'
import './Admin.css'

const UbahProduk = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <HeaderAdmin />
            <BannerTop />
            <DataUbahProduk />
            <BannerBottom />
            <Media />
            <Copyright />
        </>
    )
}

export default UbahProduk
