import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { GlobalState } from '../../../context/GlobalContext'

const DataAdminProfil = () => {

    const { pemdes, baseUrl } = useContext(GlobalState)
    const [dusun, setDusun] = useState('')
    const [info, setInfo] = useState(null)
    const [wilayah, setWilayah] = useState([])
    const [formUbahWilayah, setFormUbahWilayah] = useState(false)
    const [edit, setEdit] = useState(null)
    const [idx, setIdx] = useState(null)

    const apiWilayah = `${baseUrl}/api/wilayah/wilayah.php`

    const getApiWilayah = async () => {
        await axios.get(apiWilayah)
            .then(response => {
                setWilayah(response.data)
            })
    }

    const tambahDusun = () => {
        var FormData = require('form-data');
        var body = new FormData();
        body.append('dusun', dusun);

        var config = {
            method: 'post',
            url: `${baseUrl}/api/wilayah/tambahWilayah.php`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: body
        };

        if (dusun === "") {
            setInfo('Nama Dusun Tidak Boleh Kosong!')
            getApiWilayah()
        } else {
            axios(config)
                .then(function (response) {
                    console.log(response);
                    setInfo(null)
                    getApiWilayah()
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    const hapusDusun = (id) => {
        var config = {
            method: 'delete',
            url: `${baseUrl}/api/wilayah/hapusWilayah.php?id=${id}`,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        axios(config)
            .then(function (response) {
                console.log(response.data);
                getApiWilayah()
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const ubahWilayah = (id) => {
        if (dusun !== '') {
            var FormData = require('form-data');
            var dataBaru = new FormData();
            dataBaru.append('dusun', dusun);

            var config = {
                method: 'post',
                url: `${baseUrl}/api/wilayah/ubahWilayah.php?id=${id}`,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: dataBaru
            };

            axios(config)
                .then(function (response) {
                    console.log((response.data));
                    setFormUbahWilayah(false)
                    getApiWilayah()
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }

    const formUbahData = (id) => {
        setFormUbahWilayah(true)
        setIdx(id)
        setEdit(true)
    }

    const handleUbah = () => {
        ubahWilayah(idx)
        setFormUbahWilayah(false)
        setEdit(false)
        getApiWilayah()
    }

    const handleCancel = () => {
        setFormUbahWilayah(false)
        setInfo(null)
        setEdit(false)
    }

    useEffect(() => {
        getApiWilayah()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <>
            <span className="deskripsi-title">Data Profil Desa</span>
            <div className="deskripsi">
                <span className="deskripsi-subtitle">Profil Umum</span>
                <div className="detail-perangkat">
                    <table className="tabel-perangkat">
                        <tbody>
                            <tr>
                                <td>Nama Desa</td>
                                <td>:</td>
                                <td>{pemdes.desa}</td>
                            </tr>
                            <tr>
                                <td>Alamat Kantor</td>
                                <td>:</td>
                                <td>{pemdes.alamat}</td>
                            </tr>
                            <tr>
                                <td>Kecamatan</td>
                                <td>:</td>
                                <td>{pemdes.kecamatan}</td>
                            </tr>
                            <tr>
                                <td>Kabupaten/Kota</td>
                                <td>:</td>
                                <td>{pemdes.kabupaten}</td>
                            </tr>
                            <tr>
                                <td>Propinsi</td>
                                <td>:</td>
                                <td>{pemdes.propinsi}</td>
                            </tr>
                            <tr>
                                <td>Kode Pos</td>
                                <td>:</td>
                                <td>{pemdes.kodePos}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="deskripsi">
                <span className="deskripsi-subtitle">Data Wilayah Administratif</span>
                <span>Tambah data dusun :</span>
                <div className="data-content">
                    <form onSubmit={tambahDusun}>
                        <div className="item-data">
                            <span>Nama Dusun :</span>
                            <input type="text" nama="nama-dusun" placeholder="Nama Dusun" onChange={(e) => { setDusun(e.target.value) }} required />
                        </div>
                        {dusun === "" && <div className="alert-info">{info}</div>}
                        <div className="tambah-btn" onClick={tambahDusun}>
                            Tambah
                        </div>
                    </form>
                </div>
                <div className="detail-content">
                    <table className="styled-table">
                        <thead>
                            <tr>
                                <td>No</td>
                                <td>Nama Dusun</td>
                                <td>Aksi</td>
                            </tr>
                        </thead>
                        <tbody>
                            {wilayah.map((item, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{idx + 1}</td>
                                        <td>{item.dusun}</td>
                                        <td>
                                            <div className="action">
                                                <i id="icon-edit" className="fa fa-pencil-square-o" aria-hidden="true" title="Edit Data" onClick={() => formUbahData(item.id)}></i>
                                                <i id="icon-remove" className="fa fa-trash" aria-hidden="true" title="Hapus Data" onClick={() => hapusDusun(item.id)}></i>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {formUbahWilayah ? (
                    <div className="pop-up-ubah-data">
                        <div className="ubah-data-container">
                            <span className="ubah-data-subtitle">Ubah data wilayah :</span>
                            {(edit && dusun === "") && <div className="alert-info">{info}</div>}
                            <div className="pop-up-data-content">
                                <form>
                                    <input type="text" nama="dusun" placeholder="Dusun" onChange={e => setDusun(e.target.value)} />
                                    {(dusun === "") && <div className="alert-info">{info}</div>}
                                    <div className="btn-text" onClick={handleUbah}>
                                        <span>Ok</span>
                                    </div>
                                </form>
                                <div className="close-btn" onClick={handleCancel}>
                                    <i id="close-btn" className="fa fa-times" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div className="overlay"></div>
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default DataAdminProfil
