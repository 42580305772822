import React from 'react'
import { Link } from 'react-router-dom'
import profilImage from '../../assets/icon/icon-profil-desa.png'
import infografisImage from '../../assets/icon/icon-infografis.png'
import asetImage from '../../assets/icon/icon-aset-desa.png'
import lembagaImage from '../../assets/icon/icon-lembaga.png'
import apbdesaImage from '../../assets/icon/icon-apbdesa.png'
import perangkatImage from '../../assets/icon/icon-perangkat-desa.png'
import paymentImage from '../../assets/icon/icon-payment.png'
// import infoImage from '../../assets/icon/icon-informasi.png'
import suratImage from '../../assets/icon/icon-surat.png'
import './Menu.css'

const Menu = () => {
    return (
        <div className="menu-container">
            <h3>Menu Utama</h3>
            <div className="menu-icon">
                <Link to='/profil'>
                    <div className="icon">
                        <img src={profilImage} alt="icon" />
                        <span>Profil Desa</span>
                    </div>
                </Link>
                <Link to='/monografi'>
                    <div className="icon">
                        <img src={infografisImage} alt="icon" />
                        <span>Data Monografi</span>
                    </div>
                </Link>
                <Link to='/rkpdesa'>
                    <div className="icon">
                        <img src={perangkatImage} alt="icon" />
                        <span>Data RKPDesa</span>
                    </div>
                </Link>
                <Link to='/apbdesa'>
                    <div className="icon">
                        <img src={apbdesaImage} alt="icon" />
                        <span>Data APBDes</span>
                    </div>
                </Link>
            </div>
            <div className="menu-icon">
                <Link to='/lembaga'>
                    <div className="icon">
                        <img src={lembagaImage} alt="icon" />
                        <span>Lembaga Desa</span>
                    </div>
                </Link>
                <Link to='/galeri-produk'>
                    <div className="icon">
                        <img src={paymentImage} alt="icon" />
                        <span>Produk UMKM</span>
                    </div>
                </Link>
                <Link to='/surat-elektronik'>
                    <div className="icon">
                        <img src={suratImage} alt="icon" />
                        <span>Surat Elektronik</span>
                    </div>
                </Link>
                <Link to='/aset'>
                    <div className="icon">
                        <img src={asetImage} alt="icon" />
                        <span>Asset Desa</span>
                    </div>
                </Link>
                {/* <Link to='/papan-informasi'>
                    <div className="icon">
                        <img src={infoImage} alt="icon" />
                        <span>Papan Info</span>
                    </div>
                </Link> */}
            </div>
        </div>
    )
}

export default Menu
